import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import { history } from './history'


export const useGetQueryParams = () => {
    const location = useLocation()
    const { search } = location

    return useMemo(() => getQueryParams(search), [search])
}

export const getQueryParams = (search = history.location.search) => {
    const searchParams = new URLSearchParams(search)
    const queryParams = {}

    for (const [key, value] of searchParams) {
        queryParams[key] = value
    }

    return queryParams
}
