import React from 'react'


export default () => (
    <>
        <path
            d="M24.1591 15.1116C20.2116 15.1116 17 18.2924 17 22.2021C17 23.3757 17.2963 24.5394 17.8595 25.5716L23.7676 36.1545C23.8462 36.2956 23.9961 36.383 24.1591 36.383C24.3221 36.383 24.4719 36.2956 24.5506 36.1545L30.4609 25.5681C31.0219 24.5394 31.3182 23.3757 31.3182 22.202C31.3182 18.2924 28.1065 15.1116 24.1591 15.1116ZM24.1591 25.7473C22.1854 25.7473 20.5796 24.1569 20.5796 22.2021C20.5796 20.2473 22.1854 18.6568 24.1591 18.6568C26.1328 18.6568 27.7386 20.2473 27.7386 22.2021C27.7386 24.1569 26.1328 25.7473 24.1591 25.7473Z"
            fill="#333333"
        />
    </>
)
