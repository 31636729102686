import React from 'react'
import PropTypes from 'prop-types'

import { IconNotFound } from '../../../resources/icons'

import './NotFoundScreen.scss'


const propTypes = {
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
}

const NotFoundScreen = ({ message }) => (
    <div className="ss-not-found-screen-wrapper">
        <div className="icon-wrapper">
            <IconNotFound />
        </div>
        <div className="content-text">
            {message}
        </div>
    </div>
)

NotFoundScreen.propTypes = propTypes

export default NotFoundScreen
