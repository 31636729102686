import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import ForecastCard from './ForecastCard'
import { TRANSPORT_COLUMN_DESC_VIEW } from './constants'
import './ForecastRow.scss'

const propTypes = {
    transportsIconsVisible: PropTypes.bool.isRequired,
    transportsNumberVisible: PropTypes.bool.isRequired,
    transportColumnDescView: PropTypes.oneOf([
        TRANSPORT_COLUMN_DESC_VIEW.whenNextBus,
        TRANSPORT_COLUMN_DESC_VIEW.whereBusNow,
        TRANSPORT_COLUMN_DESC_VIEW.busDirection,
    ]).isRequired,
    routeForecasts: PropTypes.exact({
        rid: PropTypes.string.isRequired,
        rnum: PropTypes.string.isRequired,
        rtype: PropTypes.string.isRequired,
        info: PropTypes.string,
        fores: PropTypes.arrayOf(
            PropTypes.shape({
                arrt: PropTypes.number.isRequired,
                last: PropTypes.string.isRequired,
                where: PropTypes.string.isRequired,
                gos_num: PropTypes.string.isRequired,
                obj_tags: PropTypes.arrayOf(PropTypes.string).isRequired,
                obj_id: PropTypes.string.isRequired,
            }).isRequired,
        ).isRequired,
    }),
}

const ForecastRow = ({ transportsIconsVisible, transportsNumberVisible, transportColumnDescView, routeForecasts }) => {
    const forecast = routeForecasts.fores.length > 0 ? routeForecasts.fores[0] : { last: '', where: '' }

    let className = `sfp-forecast-row-wrapper ${routeForecasts.length === 1 ? 'single' : 'multiple'}`
    if (transportColumnDescView !== TRANSPORT_COLUMN_DESC_VIEW.whenNextBus) {
        className += ' sfp-forecast-row-3-columns'
    }

    return (
        <div className={className}>
            <div className={`sfp-forecast-row-number ${routeForecasts.rtype}`}>{routeForecasts.rnum}</div>
            {routeForecasts.fores.length > 0 ? (
                routeForecasts.fores
                    .slice(0, transportColumnDescView === TRANSPORT_COLUMN_DESC_VIEW.whenNextBus ? 3 : 1)
                    .map(forecast => (
                        <ForecastCard
                            key={forecast.obj_id}
                            transportsIconsVisible={transportsIconsVisible}
                            transportsNumberVisible={transportsNumberVisible}
                            forecast={forecast}
                        />
                    ))
            ) : (
                <div className="sfp-grid-forecast-card-no-data">
                    {routeForecasts.info === 'no_transports_in_our_direction' ? (
                        <FormattedMessage id="pages.stationForecasts.title.menu.otherDirection" />
                    ) : (
                        <FormattedMessage id="pages.stationForecasts.title.menu.noData" />
                    )}
                </div>
            )}
            {transportColumnDescView === TRANSPORT_COLUMN_DESC_VIEW.whereBusNow && (
                <div className="description">{forecast.last}</div>
            )}
            {transportColumnDescView === TRANSPORT_COLUMN_DESC_VIEW.busDirection && (
                <div className="description">{forecast.where}</div>
            )}
        </div>
    )
}

ForecastRow.propTypes = propTypes

export default ForecastRow
