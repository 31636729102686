import React from 'react'


const propTypes = {}

const Svg = () => (
    <svg
        width="14"
        height="11"
        viewBox="0 0 14 11"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            style={{ fill: 'white', stroke: 'white' }}
            d="M12.8648 1.13518C12.6846 0.954941 12.3924 0.954941 12.2121 1.13518L4.73059 8.6167L1.78789 5.67397C1.60768 5.49372 1.31543 5.49372 1.13518 5.67397C0.954941 5.85421 0.954941 6.14643 1.13518 6.32669L4.40427 9.59578C4.58444 9.77594 4.87677 9.77599 5.05698 9.59578L12.8648 1.78789C13.045 1.60763 13.045 1.31541 12.8648 1.13518Z"
        />
    </svg>
)

Svg.propTypes = propTypes

export default Svg
