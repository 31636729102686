import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useIntl } from 'react-intl'

import {
    getTransportColumnDescView,
    setTransportColumnDescView,
} from '../../../store/stationForecastPageSlice'
import { DialogLineCheckbox, Dialog } from '../../../components/Dialog'

import { TRANSPORT_COLUMN_DESC_VIEW } from './constants'


const propTypes = {
    opened: PropTypes.bool.isRequired,
    handleCloseDialog: PropTypes.func.isRequired,
}

/* eslint react/prop-types: ["error", { ignore: [transportColumnDescView, setTransportColumnDescView] }] */
const TransportColumnDescViewBody = ({
    opened,
    handleCloseDialog,
    transportColumnDescView,
    setTransportColumnDescView,
}) => {
    const intl = useIntl()

    const setTransportColumnDescViewHandler = useCallback((arg) => {
        setTransportColumnDescView(arg)
        handleCloseDialog()
    }, [setTransportColumnDescView, handleCloseDialog])

    if (!opened) {
        return null
    }

    return (
        <Dialog
            opened
            handleCloseDialog={handleCloseDialog}
            title={intl.formatMessage({ id: 'pages.stationForecasts.footer.menu.settings' })}
        >
            <DialogLineCheckbox
                type="radio"
                checked={transportColumnDescView === TRANSPORT_COLUMN_DESC_VIEW.whenNextBus}
                title={intl.formatMessage({ id: 'pages.stationForecasts.title.menu.following' })}
                handleArg={TRANSPORT_COLUMN_DESC_VIEW.whenNextBus}
                handleCheck={setTransportColumnDescViewHandler}
            />
            <DialogLineCheckbox
                type="radio"
                checked={transportColumnDescView === TRANSPORT_COLUMN_DESC_VIEW.whereBusNow}
                title={intl.formatMessage({ id: 'pages.stationForecasts.title.menu.current' })}
                handleArg={TRANSPORT_COLUMN_DESC_VIEW.whereBusNow}
                handleCheck={setTransportColumnDescViewHandler}
            />
            <DialogLineCheckbox
                type="radio"
                checked={transportColumnDescView === TRANSPORT_COLUMN_DESC_VIEW.busDirection}
                title={intl.formatMessage({ id: 'pages.stationForecasts.title.menu.direction' })}
                handleArg={TRANSPORT_COLUMN_DESC_VIEW.busDirection}
                handleCheck={setTransportColumnDescViewHandler}
            />
        </Dialog>
    )
}

const TransportColumnDescView = connect(state => ({
    transportColumnDescView: getTransportColumnDescView(state),
}), {
    setTransportColumnDescView,
})(TransportColumnDescViewBody)

TransportColumnDescViewBody.propTypes = propTypes
TransportColumnDescView.propTypes = propTypes

export default TransportColumnDescView
