import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { Link, useLocation } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { connect } from 'react-redux'

import { IconBack, IconFilter, IconSound } from '../../resources/icons'
import { getExceptTransportType, getGoalZoneIds } from '../../store/stationForecastPageSlice'

import './ViewMode.scss'

const propTypes = {
    route: PropTypes.oneOf(['grid', 'filter', 'speaker']),
}

/* eslint react/prop-types: ["off"] */
const ViewModeBody = ({ route, isFilterPure }) => {
    const intl = useIntl()
    const location = useLocation()

    const speakerLinkClicked = useCallback(
        (e) => {
            if (!window.SpeechSynthesisUtterance) {
                // eslint-disable-next-line
                alert(intl.formatMessage({ id: 'pages.stationForecasts.noSpeechAlert' }))
                e.preventDefault()
            }
        },
        [intl],
    )

    return (
        <div className="sfp-view-mode-wrapper">
            {route !== 'grid' && (
                <Link to={`/station_forecasts${location.search}`} className="link">
                    <IconBack />
                    <span className="label">
                        {intl.formatMessage({ id: 'pages.stationForecasts.mainButtons.showTable' })}
                    </span>
                </Link>
            )}
            {route === 'grid' && (
                <Link to={`/station_forecasts/filter${location.search}`} className="link">
                    <IconFilter checked={!isFilterPure} />
                    <span className="label">
                        {intl.formatMessage({ id: 'pages.stationForecasts.mainButtons.showFilter' })}
                    </span>
                </Link>
            )}
            {route === 'grid' && (
                <Link to={`/station_forecasts/speaker${location.search}`} className="link" onClick={speakerLinkClicked}>
                    <IconSound />
                    <span className="label">
                        {intl.formatMessage({ id: 'pages.stationForecasts.mainButtons.showSpeaker' })}
                    </span>
                </Link>
            )}
        </div>
    )
}

const ViewMode = connect((state) => {
    const exceptTransportType = getExceptTransportType(state)
    const goalZoneIds = getGoalZoneIds(state)

    const isFilterPure = exceptTransportType.length === 0 && goalZoneIds.length === 0

    return { isFilterPure }
})(ViewModeBody)

ViewMode.propTypes = propTypes

export default ViewMode
