import React from 'react'
import PropTypes from 'prop-types'


const propTypes = {
    color: PropTypes.string,
}

const Svg = ({ color }) => (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect
            className="fill-stroke"
            style={{ stroke: color }}
            x="0.6"
            y="0.6"
            width="18.8"
            height="18.8"
            rx="1.4"
            strokeWidth="1.2"
            fill="none"
        />
        <path
            className="fill"
            style={{ fill: color }}
            d="M3.64844 8.28516H4.65234C5.23828 8.28516 5.60547 8.63672 5.60547 9.18359C5.60547 9.74219 5.25781 10.082 4.66406 10.082H3.64844V8.28516ZM3.64844 10.9375H4.59375L5.64453 13H6.98047L5.79297 10.7617C6.44141 10.5078 6.81641 9.88281 6.81641 9.16016C6.81641 8.04688 6.07422 7.36328 4.77734 7.36328H2.46875V13H3.64844V10.9375ZM8.77344 7.36328H7.59375V11.0078C7.59375 12.25 8.51172 13.0977 9.95703 13.0977C11.3984 13.0977 12.3164 12.25 12.3164 11.0078V7.36328H11.1367V10.8906C11.1367 11.6133 10.7109 12.0859 9.95703 12.0859C9.19922 12.0859 8.77344 11.6133 8.77344 10.8906V7.36328ZM13.043 11.3906C13.0781 12.4531 13.9727 13.0977 15.3164 13.0977C16.7266 13.0977 17.5898 12.3984 17.5898 11.2969C17.5898 10.4414 17.0703 9.96094 15.8789 9.72266L15.2812 9.60156C14.6172 9.46875 14.3438 9.25391 14.3438 8.90625C14.3438 8.49219 14.7344 8.21875 15.3164 8.21875C15.9102 8.21875 16.3281 8.50781 16.3672 8.98828H17.4805C17.4609 7.95312 16.6328 7.26562 15.3164 7.26562C14.0625 7.26562 13.1641 7.94922 13.1641 8.98047C13.1641 9.82031 13.7148 10.3594 14.8398 10.582L15.4336 10.6992C16.1406 10.8438 16.4062 11.0547 16.4062 11.4258C16.4062 11.8398 15.9766 12.1445 15.3477 12.1445C14.707 12.1445 14.2305 11.8633 14.1562 11.3906H13.043Z"
        />
    </svg>
)

Svg.propTypes = propTypes

export default Svg
