import React, { useCallback } from 'react'
import { connect } from 'react-redux'
import { useIntl } from 'react-intl'
import { useLocation } from 'react-router-dom'

import { IconFavorites, IconFavoritesActive } from '../../resources/icons'
import { useGetQueryParams } from '../../services/useGetQuery'
import { addToFavorites, getFavorites, removeFromFavorites } from '../../store/favoritesPageSlice'

import './Footer.scss'

/* eslint react/prop-types: ["off"] */
const Footer = ({ favorites, addToFavorites, removeFromFavorites }) => {
    const intl = useIntl()
    const location = useLocation()
    const { sid: stringSid } = useGetQueryParams()
    const sid = +stringSid

    const showFavoritesButton = location.pathname === '/station_forecasts' && sid > 0
    const isStationInFavorites = favorites.indexOf(sid) > -1

    // Favorites
    const handleToggleFavorites = useCallback(() => {
        if (isStationInFavorites) {
            removeFromFavorites(sid)
        } else {
            addToFavorites(sid)
        }
    }, [sid, isStationInFavorites, addToFavorites, removeFromFavorites])

    return (
        <>
            {showFavoritesButton && (
                <div className="footer-wrapper">
                    <div onClick={handleToggleFavorites} className="menu-button" aria-hidden>
                        {isStationInFavorites ? (
                            <>
                                <IconFavoritesActive />
                                <div className="menu-button-label">
                                    {intl.formatMessage({
                                        id: 'pages.favorites.footer.menu.unfavorite',
                                    })}
                                </div>
                            </>
                        ) : (
                            <>
                                <IconFavorites />
                                <div className="menu-button-label">
                                    {intl.formatMessage({
                                        id: 'pages.favorites.footer.menu.favorite',
                                    })}
                                </div>
                            </>
                        )}
                    </div>
                </div>
            )}
        </>
    )
}

export default connect(
    state => ({
        favorites: getFavorites(state),
    }),
    {
        addToFavorites,
        removeFromFavorites,
    },
)(Footer)
