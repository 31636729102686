import React from 'react'
import { Link, useRouteMatch } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { connect } from 'react-redux'

import { getTransportTypes } from '../../store/stationSearchByTypePageSlice'
import { IconArrowForward } from '../../resources/icons'

import './TypeSelector.scss'


/* eslint react/prop-types: ["off"] */
const TypeSelector = ({ transportTypes }) => {
    const intl = useIntl()
    const routeMatch = useRouteMatch()

    return (
        <>
            <div className="ssbtp-type-selector-title">
                {intl.formatMessage({
                    id: 'pages.stationSearchByType.selector.title',
                })}
            </div>
            {!!transportTypes.length && (
                <div className="ssbtp-type-selector-wrapper">
                    {transportTypes.map(item => (
                        <Link
                            key={item.id}
                            to={`${routeMatch.path}?tid=${item.id}`}
                            className="link-item"
                        >
                            <div className="selector-item">
                                <div className={`selector-item-badge ${item.type}`}>
                                    {intl.formatMessage({
                                        id: `pages.stationSearchByType.selector.shortcut.${item.type}`,
                                    })}
                                </div>
                                <div className="selector-item-title">
                                    {intl.formatMessage({
                                        id: `pages.stationSearchByType.selector.title.${item.type}`,
                                    })}
                                </div>
                                <IconArrowForward />
                            </div>
                        </Link>
                    ))}
                </div>
            )}
        </>
    )
}

export default connect(state => ({
    transportTypes: getTransportTypes(state),
}))(TypeSelector)
