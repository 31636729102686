import React from 'react'

const propTypes = {}

const Svg = () => (
    <svg width="123" height="143" viewBox="0 0 123 143" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M109.132 36H115.348C116.177 36 116.848 36.6716 116.848 37.5C116.848 38.3284 116.177 39 115.348 39H106.363L106.186 39.2564C105.872 39.7116 105.423 40.3616 104.881 41.1415C103.796 42.701 102.34 44.7815 100.853 46.8629C99.368 48.9422 97.8464 51.031 96.6333 52.6035C96.0286 53.3875 95.4893 54.0578 95.0641 54.5378C94.8539 54.7751 94.6534 54.9867 94.4756 55.1463C94.3883 55.2247 94.2833 55.3115 94.1684 55.3847C94.0943 55.4318 93.8465 55.5886 93.5126 55.5886C93.268 55.5886 93.0563 55.518 92.9208 55.4638C92.771 55.4039 92.624 55.3265 92.4883 55.2466C92.2164 55.0867 91.916 54.8731 91.6124 54.6396C91.0014 54.1696 90.2954 53.5547 89.6358 52.9552C88.9729 52.3525 88.3418 51.7515 87.8775 51.3021C87.6451 51.0771 87.4537 50.8895 87.3202 50.7579C87.2534 50.692 87.201 50.6402 87.1652 50.6046L87.1241 50.5637L87.1097 50.5493C87.1096 50.5492 87.1093 50.5489 87.8164 49.8418L87.1097 50.5493C86.7191 50.1588 86.7188 49.5252 87.1093 49.1347C87.4998 48.7442 88.133 48.7442 88.5235 49.1347L88.536 49.1472L88.5747 49.1857C88.6089 49.2196 88.6594 49.2697 88.7243 49.3337C88.8541 49.4616 89.041 49.6449 89.2684 49.8649C89.7237 50.3056 90.3387 50.8913 90.9812 51.4753C91.627 52.0624 92.2857 52.6343 92.8318 53.0543C93.037 53.2122 93.2151 53.3399 93.3627 53.4359C93.423 53.372 93.491 53.2974 93.5669 53.2117C93.9465 52.7832 94.453 52.1555 95.0497 51.3819C96.2397 49.8393 97.7442 47.7746 99.2258 45.7004C100.706 43.6284 102.157 41.5554 103.238 39.9998C103.779 39.2222 104.227 38.5742 104.54 38.1208C104.661 37.9448 104.762 37.7982 104.84 37.6846C104.837 37.661 104.835 37.6371 104.833 37.6132C104.819 37.4306 104.839 37.2447 104.893 37.0652C104.938 36.9159 105.006 36.7765 105.092 36.6509L106.98 33.8198C107.439 33.1305 108.371 32.9443 109.06 33.4038C109.749 33.8633 109.935 34.7947 109.476 35.4839L109.132 36Z"
            fill="#45C1FF"
        />
        <path
            d="M12.2186 40.0952C12.2736 40.0963 12.3287 40.0943 12.3837 40.0894C12.7175 40.4999 13.1407 41.019 13.6299 41.6162C14.8769 43.1384 16.5543 45.1693 18.2732 47.2013C19.9901 49.2309 21.7567 51.2712 23.1799 52.8079C23.8894 53.574 24.5277 54.2303 25.0388 54.7007C25.2921 54.9339 25.5346 55.1404 25.7516 55.2946C25.8595 55.3713 25.9822 55.4502 26.1122 55.5137C26.2212 55.5669 26.4379 55.6616 26.7064 55.6616C27.1336 55.6616 27.5373 55.4753 27.816 55.3242C28.1312 55.1533 28.4674 54.9242 28.8009 54.6733C29.4704 54.1695 30.2155 53.5118 30.8997 52.873C31.5878 52.2305 31.2323 52.5901 31.7036 52.1117C31.9396 51.8721 32.1331 51.6722 32.2679 51.5318C32.3353 51.4616 32.3881 51.4063 32.4243 51.3682L32.4659 51.3244L32.4798 51.3096L32.4807 51.3087C32.4807 51.3087 32.4811 51.3083 31.7532 50.6226L32.4807 51.3087C32.8594 50.9067 32.8409 50.2734 32.4389 49.8947C32.0369 49.516 31.404 49.5349 31.0253 49.9369L31.0131 49.9498L30.9748 49.9902C30.9408 50.026 30.8904 50.0788 30.8254 50.1464C30.6956 50.2816 30.5081 50.4754 30.2788 50.7082C29.8194 51.1745 30.1959 50.7939 29.5348 51.4112C28.8698 52.0321 28.1845 52.6342 27.5984 53.0752C27.304 53.2967 27.0561 53.4612 26.8629 53.5659C26.8453 53.5754 26.8288 53.5841 26.8135 53.5919C26.705 53.507 26.5652 53.3874 26.3932 53.2291C25.941 52.813 25.3453 52.2027 24.6473 51.4489C23.2552 49.9458 21.5118 47.933 19.8001 45.9096C18.0904 43.8885 16.4202 41.8663 15.177 40.3488C14.5921 39.6347 14.1019 39.0327 13.747 38.5956C13.7471 38.4647 13.7302 38.333 13.6953 38.2042C13.6512 38.0402 13.5799 37.8873 13.4866 37.7506L11.222 34.303C10.7672 33.6106 9.83722 33.418 9.14481 33.8728C8.4524 34.3276 8.25979 35.2576 8.7146 35.95L9.46681 37.0952H3.32275C2.49433 37.0952 1.82275 37.7668 1.82275 38.5952C1.82275 39.4236 2.49433 40.0952 3.32275 40.0952H12.2186Z"
            fill="#45C1FF"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M31.3291 20.1898C31.3291 17.4284 33.5677 15.1898 36.3291 15.1898H83.2334C85.9948 15.1898 88.2334 17.4284 88.2334 20.1898V82.7846C88.2334 85.546 85.9948 87.7846 83.2334 87.7846H36.3291C33.5677 87.7846 31.3291 85.546 31.3291 82.7846V20.1898ZM33.0712 27.0148C33.0712 25.9102 33.9666 25.0148 35.0712 25.0148H84.4915C85.5961 25.0148 86.4915 25.9102 86.4915 27.0148V83.6014C86.4915 84.706 85.5961 85.6014 84.4915 85.6014H35.0712C33.9666 85.6014 33.0712 84.706 33.0712 83.6014V27.0148ZM38.5874 20.648C39.3891 20.648 40.039 20.037 40.039 19.2834C40.039 18.5298 39.3891 17.9188 38.5874 17.9188C37.7857 17.9188 37.1357 18.5298 37.1357 19.2834C37.1357 20.037 37.7857 20.648 38.5874 20.648ZM44.6843 19.2834C44.6843 20.037 44.0344 20.648 43.2326 20.648C42.4309 20.648 41.781 20.037 41.781 19.2834C41.781 18.5298 42.4309 17.9188 43.2326 17.9188C44.0344 17.9188 44.6843 18.5298 44.6843 19.2834ZM47.878 20.648C48.6797 20.648 49.3296 20.037 49.3296 19.2834C49.3296 18.5298 48.6797 17.9188 47.878 17.9188C47.0763 17.9188 46.4263 18.5298 46.4263 19.2834C46.4263 20.037 47.0763 20.648 47.878 20.648Z"
            fill="#9D3ADC"
        />
        <path
            d="M11.2945 88.1721C11.2945 89.6794 10.0413 90.9012 8.49536 90.9012C6.94944 90.9012 5.69623 89.6794 5.69623 88.1721C5.69623 86.6649 6.94944 85.443 8.49536 85.443C10.0413 85.443 11.2945 86.6649 11.2945 88.1721Z"
            fill="white"
            stroke="#9D3ADC"
        />
        <path
            d="M13.5589 103.101C13.5589 103.94 12.8614 104.62 12.0009 104.62C11.1405 104.62 10.443 103.94 10.443 103.101C10.443 102.262 11.1405 101.582 12.0009 101.582C12.8614 101.582 13.5589 102.262 13.5589 103.101Z"
            fill="white"
            stroke="#9D3ADC"
        />
        <path
            d="M21.8039 94.346L22.8287 93.2749L21.8039 92.2038C21.7347 92.1339 21.6795 92.0504 21.6415 91.9579C21.6035 91.8655 21.5835 91.7661 21.5827 91.6655C21.5818 91.5649 21.6002 91.4652 21.6366 91.3721C21.6731 91.279 21.7269 91.1945 21.7949 91.1233C21.863 91.0522 21.9439 90.996 22.033 90.9579C22.122 90.9198 22.2175 90.9006 22.3137 90.9015C22.4099 90.9024 22.5051 90.9233 22.5935 90.963C22.6819 91.0027 22.7619 91.0604 22.8287 91.1327L23.8536 92.2038L24.8784 91.1327C24.9453 91.0604 25.0252 91.0027 25.1137 90.963C25.2021 90.9233 25.2972 90.9024 25.3934 90.9015C25.4897 90.9006 25.5851 90.9198 25.6742 90.9579C25.7633 90.996 25.8442 91.0522 25.9122 91.1233C25.9803 91.1945 26.0341 91.279 26.0705 91.3721C26.107 91.4652 26.1253 91.5649 26.1245 91.6655C26.1236 91.7661 26.1037 91.8655 26.0657 91.9579C26.0277 92.0504 25.9725 92.1339 25.9032 92.2038L24.8784 93.2749L25.9032 94.346C26.0353 94.4889 26.1083 94.6802 26.1067 94.8788C26.105 95.0774 26.0288 95.2674 25.8944 95.4078C25.76 95.5483 25.5783 95.6279 25.3882 95.6297C25.1982 95.6314 25.0151 95.5551 24.8784 95.4171L23.8536 94.346L22.8287 95.4171C22.7619 95.4894 22.6819 95.5471 22.5935 95.5868C22.5051 95.6265 22.4099 95.6474 22.3137 95.6483C22.2175 95.6492 22.122 95.63 22.033 95.5919C21.9439 95.5538 21.863 95.4976 21.7949 95.4265C21.7269 95.3553 21.6731 95.2708 21.6366 95.1777C21.6002 95.0846 21.5818 94.9848 21.5827 94.8842C21.5835 94.7837 21.6035 94.6843 21.6415 94.5919C21.6795 94.4994 21.7347 94.4159 21.8039 94.346Z"
            fill="#9D3ADC"
        />
        <path
            d="M103.481 82.476C103.481 83.9832 104.779 85.2051 106.38 85.2051C107.981 85.2051 109.279 83.9832 109.279 82.476C109.279 80.9687 107.981 79.7468 106.38 79.7468C104.779 79.7468 103.481 80.9687 103.481 82.476Z"
            fill="#9D3ADC"
            stroke="#9D3ADC"
        />
        <path
            d="M100.633 3.2749C100.633 4.78216 101.931 6.00403 103.532 6.00403C105.133 6.00403 106.431 4.78216 106.431 3.2749C106.431 1.76765 105.133 0.545776 103.532 0.545776C101.931 0.545776 100.633 1.76765 100.633 3.2749Z"
            fill="white"
            stroke="#9D3ADC"
        />
        <path
            d="M116.425 4.13341L117.79 2.8481L116.425 1.5628C116.332 1.47894 116.259 1.37865 116.208 1.26775C116.158 1.15685 116.131 1.03754 116.13 0.916842C116.129 0.796147 116.153 0.676476 116.202 0.564765C116.25 0.453055 116.322 0.351575 116.413 0.266227C116.503 0.18088 116.611 0.113369 116.73 0.0676645C116.848 0.02196 116.976 -0.00101445 117.104 3.43548e-05C117.232 0.00108316 117.359 0.0261283 117.477 0.0737673C117.594 0.121406 117.701 0.190673 117.79 0.27749L119.155 1.5628L120.521 0.27749C120.61 0.190673 120.716 0.121406 120.834 0.0737673C120.952 0.0261283 121.079 0.00108316 121.207 3.43548e-05C121.335 -0.00101445 121.462 0.02196 121.581 0.0676645C121.7 0.113369 121.807 0.18088 121.898 0.266227C121.989 0.351575 122.06 0.453055 122.109 0.564765C122.157 0.676476 122.182 0.796147 122.181 0.916842C122.18 1.03754 122.153 1.15685 122.102 1.26775C122.052 1.37865 121.978 1.47894 121.886 1.5628L120.521 2.8481L121.886 4.13341C122.062 4.30484 122.159 4.53443 122.157 4.77276C122.155 5.01109 122.053 5.23908 121.874 5.40762C121.695 5.57615 121.453 5.67174 121.2 5.67381C120.947 5.67588 120.703 5.58429 120.521 5.41871L119.155 4.13341L117.79 5.41871C117.701 5.50553 117.594 5.57474 117.477 5.62238C117.359 5.67002 117.232 5.69512 117.104 5.69617C116.976 5.69722 116.848 5.67424 116.73 5.62854C116.611 5.58283 116.503 5.51532 116.413 5.42998C116.322 5.34463 116.25 5.24315 116.202 5.13144C116.153 5.01973 116.129 4.9 116.13 4.7793C116.131 4.65861 116.158 4.53935 116.208 4.42845C116.259 4.31755 116.332 4.21726 116.425 4.13341Z"
            fill="#9D3ADC"
        />
        <path
            d="M105.291 17.7084L106.646 17.309L106.189 16.0025C106.157 15.9162 106.143 15.8252 106.149 15.7348C106.154 15.6444 106.178 15.5564 106.219 15.4759C106.261 15.3955 106.319 15.3242 106.39 15.2662C106.461 15.2082 106.544 15.1646 106.634 15.1381C106.724 15.1116 106.819 15.1026 106.914 15.1117C107.009 15.1208 107.101 15.1478 107.186 15.1911C107.271 15.2344 107.346 15.2931 107.407 15.3639C107.469 15.4346 107.515 15.516 107.544 15.6032L108.001 16.9097L109.356 16.5104C109.446 16.4823 109.541 16.4718 109.636 16.4793C109.73 16.4868 109.823 16.5123 109.909 16.5542C109.994 16.5961 110.07 16.6536 110.133 16.7233C110.196 16.7931 110.244 16.8737 110.274 16.9605C110.305 17.0472 110.317 17.1384 110.31 17.2286C110.303 17.3189 110.277 17.4064 110.235 17.4862C110.192 17.5659 110.132 17.6362 110.06 17.693C109.988 17.7498 109.904 17.7919 109.813 17.8169L108.458 18.2162L108.915 19.5227C108.972 19.6949 108.956 19.8796 108.871 20.0371C108.786 20.1945 108.639 20.312 108.462 20.3644C108.284 20.4168 108.09 20.3997 107.921 20.317C107.752 20.2343 107.623 20.0924 107.56 19.9221L107.103 18.6155L105.747 19.0149C105.658 19.0429 105.563 19.0534 105.468 19.0459C105.373 19.0384 105.281 19.0129 105.195 18.971C105.11 18.9291 105.033 18.8717 104.971 18.8019C104.908 18.7321 104.86 18.6515 104.83 18.5648C104.799 18.478 104.787 18.3869 104.794 18.2966C104.801 18.2063 104.826 18.1188 104.869 18.039C104.912 17.9593 104.972 17.889 105.044 17.8323C105.116 17.7755 105.2 17.7334 105.291 17.7084Z"
            fill="#9D3ADC"
        />
        <path
            d="M0.294816 7.93089L1.66017 6.64559L0.294816 5.36028C0.202591 5.27643 0.129033 5.17614 0.078427 5.06524C0.0278207 4.95434 0.00117837 4.83502 6.41236e-05 4.71433C-0.00105013 4.59363 0.0233816 4.47396 0.0719329 4.36225C0.120484 4.25054 0.192182 4.14906 0.282845 4.06371C0.373507 3.97837 0.481319 3.91085 0.599988 3.86515C0.718656 3.81945 0.845801 3.79647 0.974013 3.79752C1.10222 3.79857 1.22893 3.82361 1.34674 3.87125C1.46454 3.91889 1.5711 3.98816 1.66017 4.07498L3.02553 5.36028L4.39088 4.07498C4.47995 3.98816 4.58651 3.91889 4.70431 3.87125C4.82212 3.82361 4.94883 3.79857 5.07704 3.79752C5.20525 3.79647 5.3324 3.81945 5.45107 3.86515C5.56974 3.91085 5.67755 3.97837 5.76821 4.06371C5.85887 4.14906 5.93058 4.25054 5.97913 4.36225C6.02768 4.47396 6.0521 4.59363 6.05099 4.71433C6.04987 4.83502 6.02324 4.95434 5.97264 5.06524C5.92203 5.17614 5.84846 5.27643 5.75624 5.36028L4.39088 6.64559L5.75624 7.93089C5.93213 8.10233 6.02946 8.33191 6.02726 8.57024C6.02506 8.80858 5.92351 9.03657 5.74448 9.2051C5.56545 9.37363 5.32327 9.46922 5.07009 9.47129C4.81691 9.47337 4.57299 9.38178 4.39088 9.2162L3.02553 7.93089L1.66017 9.2162C1.5711 9.30301 1.46454 9.37223 1.34674 9.41986C1.22893 9.4675 1.10222 9.4926 0.974013 9.49365C0.845801 9.4947 0.718656 9.47173 0.599988 9.42602C0.481319 9.38032 0.373507 9.31281 0.282845 9.22746C0.192182 9.14211 0.120484 9.04063 0.0719329 8.92892C0.0233816 8.81721 -0.00105013 8.69748 6.41236e-05 8.57679C0.00117837 8.4561 0.0278207 8.33683 0.078427 8.22593C0.129033 8.11504 0.202591 8.01474 0.294816 7.93089Z"
            fill="#9D3ADC"
        />
        <path d="M55 55C55 55 60 58 64.4937 55" stroke="#45C1FF" strokeWidth="1.8" strokeLinecap="round" />
        <path
            d="M55 44.5C55 45.8807 53.8807 47 52.5 47C51.1193 47 50 45.8807 50 44.5C50 43.1193 51.1193 42 52.5 42C53.8807 42 55 43.1193 55 44.5Z"
            fill="#45C1FF"
        />
        <path
            d="M70 44.5C70 45.8807 68.8807 47 67.5 47C66.1193 47 65 45.8807 65 44.5C65 43.1193 66.1193 42 67.5 42C68.8807 42 70 43.1193 70 44.5Z"
            fill="#45C1FF"
        />
        <ellipse opacity="0.2" cx="61.7089" cy="103.956" rx="26.5823" ry="3.32278" fill="#9D3ADC" />
    </svg>
)

Svg.propTypes = propTypes

export default Svg
