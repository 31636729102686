import React from 'react'
import PropTypes from 'prop-types'


const propTypes = {
    color: PropTypes.string,
}

const Svg = ({ color }) => (
    <svg
        style={{ verticalAlign: 'middle' }}
        width="23"
        height="18"
        viewBox="0 0 23 18"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            className="fill"
            style={{ fill: color }}
            d="M12.3397 6.13278L12.3398 6.13277C12.6437 5.82877 13.1366 5.82883 13.4407 6.1329C15.0215 7.71373 15.0215 10.2862 13.4407 11.8669L13.4407 11.867C13.2887 12.019 13.0896 12.095 12.8902 12.095C12.6908 12.095 12.4917 12.019 12.3397 11.8671L12.3397 11.867C12.0357 11.5631 12.0357 11.0702 12.3397 10.7661L12.3398 10.7661C13.3136 9.7922 13.3136 8.20764 12.3398 7.23372L12.3397 7.2337C12.0357 6.92978 12.0357 6.43683 12.3397 6.13278Z"
            stroke="white"
            strokeWidth="0.2"
        />
        <path
            className="fill"
            style={{ fill: color }}
            d="M14.9148 3.55792C14.9148 3.55792 14.9148 3.55792 14.9148 3.55791C15.2188 3.25401 15.7117 3.25399 16.0158 3.55794C19.0164 6.55868 19.0164 11.4415 16.0158 14.4421C15.8638 14.5941 15.6647 14.6701 15.4653 14.6701C15.2659 14.6701 15.0668 14.5941 14.9148 14.4421C14.6108 14.1381 14.6108 13.6452 14.9148 13.3411C17.3086 10.9474 17.3086 7.0527 14.9148 4.65897C14.6108 4.35494 14.6108 3.86209 14.9148 3.55792Z"
            stroke="white"
            strokeWidth="0.2"
        />
        <path
            className="fill"
            style={{ fill: color }}
            d="M17.7882 1.78519C17.4842 1.48116 17.4842 0.988299 17.7882 0.684132C18.0923 0.380127 18.5852 0.380313 18.8892 0.684156C21.1105 2.90563 22.3338 5.85851 22.3337 8.99983C22.3337 12.1414 21.1105 15.0944 18.8892 17.3158C18.7372 17.4677 18.5381 17.5437 18.3387 17.5437C18.1393 17.5437 17.9402 17.4677 17.7882 17.3158C17.4842 17.0117 17.4842 16.5189 17.7882 16.2147C19.7153 14.2878 20.7767 11.7252 20.7767 8.99995C20.7767 6.27483 19.7153 3.71222 17.7882 1.78519Z"
            stroke="white"
            strokeWidth="0.2"
        />
        <path
            className="fill"
            style={{ fill: color }}
            d="M9.91039 0.171166L9.91046 0.171199C10.1869 0.298039 10.3642 0.574472 10.3642 0.878598V17.1215C10.3642 17.4256 10.187 17.7019 9.91044 17.8289L9.91041 17.8289C9.80647 17.8767 9.69576 17.9 9.58576 17.9C9.40294 17.9 9.22224 17.8356 9.07814 17.7119C9.07813 17.7119 9.07812 17.7119 9.07812 17.7119L3.49576 12.9147L3.46765 12.8905H3.43058H0.878525C0.448565 12.8905 0.1 12.5421 0.1 12.112V5.88807C0.1 5.45801 0.448565 5.10954 0.878525 5.10954H3.43058H3.46765L3.49576 5.08538L9.07814 0.288167L9.07817 0.288142C9.3088 0.0897886 9.63388 0.0440177 9.91039 0.171166ZM1.75705 6.66659H1.65705V6.76659V11.2335V11.3335H1.75705H2.8777H2.9777V11.2335V6.76659V6.66659H2.8777H1.75705ZM8.64195 15.2839L8.80712 15.4258V15.208V2.79191V2.57412L8.64195 2.71607L4.56957 6.21564L4.53475 6.24557V6.29148V11.7085V11.7544L4.56957 11.7843L8.64195 15.2839Z"
            stroke="white"
            strokeWidth="0.2"
        />
    </svg>
)

Svg.propTypes = propTypes

export default Svg
