import React from 'react'
import PropTypes from 'prop-types'

import { IconLoading } from '../../../resources/icons'

import './LoadingScreen.scss'


const propTypes = {
    message: PropTypes.string,
}

const LoadingScreen = ({ message }) => (
    <div className="ss-loading-screen-wrapper">
        <div className="icon-wrapper">
            <IconLoading />
        </div>
        <div className="content-text">
            {message}
        </div>
    </div>
)

LoadingScreen.propTypes = propTypes

export default LoadingScreen
