import React, { useEffect } from 'react'
import { useRouteMatch, Redirect, Route, Switch } from 'react-router-dom'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import { useIntl } from 'react-intl'

import { useGetQueryParams } from '../../services/useGetQuery'
import { pageOpened, pageClosed, getStationInfo } from '../../store/stationForecastPageSlice'
import { addToHistory } from '../../store/historyPageSlice'

import StationInfo from './StationInfo'
import ViewMode from './ViewMode'
import Filter from './Filter'
import Grid from './Grid'
import Speaker from './Speaker'

/* eslint react/prop-types: ["off"] */
const StationForecastPage = ({ pageOpened, pageClosed, stationInfo, addToHistory }) => {
    const routeMatch = useRouteMatch()
    const intl = useIntl()
    const { sid, test: isTest } = useGetQueryParams()

    useEffect(() => {
        pageOpened({ stationId: sid, isTest })

        return () => {
            pageClosed()
        }
    }, [pageOpened, pageClosed, sid, isTest])

    // добавление станции в список посещенных
    useEffect(() => {
        if (sid > 0 && stationInfo) {
            addToHistory(+sid)
        }
    }, [sid, stationInfo, addToHistory])

    if (!stationInfo) {
        return null
    }

    return (
        <>
            <Helmet>
                <meta
                    name="description"
                    content={intl.formatMessage({ id: 'pages.stationForecasts.htmlHead.title' })}
                />
                <title>{intl.formatMessage({ id: 'pages.stationForecasts.htmlHead.title' })}</title>
            </Helmet>

            <StationInfo />

            <Switch>
                <Route path={routeMatch.path} exact>
                    <ViewMode route="grid" />

                    <Grid />
                </Route>

                <Route path={`${routeMatch.path}/filter`}>
                    <ViewMode route="filter" />

                    <Filter stationId={sid} />
                </Route>

                <Route path={`${routeMatch.path}/speaker`}>
                    <ViewMode route="speaker" />

                    <Speaker />
                </Route>

                <Route>
                    <Redirect to={routeMatch.path} />
                </Route>
            </Switch>
        </>
    )
}

export default connect(
    state => ({
        stationInfo: getStationInfo(state),
    }),
    {
        pageOpened,
        pageClosed,
        addToHistory,
    },
)(StationForecastPage)
