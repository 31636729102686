import React from 'react'
import PropTypes from 'prop-types'


const propTypes = {
    color: PropTypes.string,
}

const Svg = ({ color }) => (
    <svg
        width="20"
        height="21"
        viewBox="0 0 20 21"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            className="fill"
            style={{ fill: color }}
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4 20.7071C5.30622 20.7071 6.41746 19.8723 6.82929 18.7071H15.5C17.9853 18.7071 20 16.6924 20 14.2071C20 11.7218 17.9853 9.70711 15.5 9.70711H4.5C3.11929 9.70711 2 8.58782 2 7.20711C2 5.82639 3.11929 4.70711 4.5 4.70711H7.58579L6.29289 6L7.70711 7.41421L11.4142 3.70711L7.70711 0L6.29289 1.41421L7.58579 2.70711H4.5C2.01472 2.70711 0 4.72183 0 7.20711C0 9.69239 2.01472 11.7071 4.5 11.7071H15.5C16.8807 11.7071 18 12.8264 18 14.2071C18 15.5878 16.8807 16.7071 15.5 16.7071H6.82929C6.41746 15.5419 5.30622 14.7071 4 14.7071C2.34315 14.7071 1 16.0503 1 17.7071C1 19.364 2.34315 20.7071 4 20.7071ZM3 17.7071C3 17.1548 3.44772 16.7071 4 16.7071C4.55228 16.7071 5 17.1548 5 17.7071C5 18.2594 4.55228 18.7071 4 18.7071C3.44772 18.7071 3 18.2594 3 17.7071ZM16 0.707107C17.6569 0.707107 19 2.05025 19 3.70711C19 5.36396 17.6569 6.70711 16 6.70711C14.3431 6.70711 13 5.36396 13 3.70711C13 2.05025 14.3431 0.707107 16 0.707107ZM15 3.70711C15 3.15482 15.4477 2.70711 16 2.70711C16.5523 2.70711 17 3.15482 17 3.70711C17 4.25939 16.5523 4.70711 16 4.70711C15.4477 4.70711 15 4.25939 15 3.70711Z"
        />
    </svg>
)

Svg.propTypes = propTypes

export default Svg
