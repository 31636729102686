import React from 'react'
import PropTypes from 'prop-types'


const propTypes = {
    color: PropTypes.string,
    checked: PropTypes.bool,
}

const Svg = ({ color, checked }) => (
    <svg
        width="23"
        height="28"
        viewBox="0 0 23 28"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clipPath="url(#clip0)">
            <path
                className="fill"
                style={{ fill: color }}
                d="M17.3571 13.3572H6.98014C6.63153 11.9874 5.23852 11.1597 3.86874 11.5083C2.96035 11.7395 2.25104 12.4488 2.01984 13.3572H0.642869C0.287816 13.3572 0 13.645 0 14C0 14.3551 0.287816 14.6429 0.642869 14.6429H2.01988C2.36849 16.0126 3.7615 16.8404 5.13128 16.4918C6.03966 16.2606 6.74898 15.5513 6.98018 14.6429H17.3571C17.7122 14.6429 18 14.3551 18 14C18 13.645 17.7122 13.3572 17.3571 13.3572ZM4.50001 15.2857C3.78994 15.2857 3.21431 14.7101 3.21431 14C3.21431 13.29 3.78994 12.7143 4.50001 12.7143C5.21008 12.7143 5.78571 13.29 5.78571 14C5.78571 14.7101 5.21008 15.2857 4.50001 15.2857Z"
            />
            <path
                className="fill"
                style={{ fill: color }}
                d="M17.3571 6.92822H15.3373C14.9887 5.55848 13.5957 4.7307 12.2259 5.07931C11.3175 5.31052 10.6082 6.01983 10.377 6.92822H0.642869C0.287816 6.92822 0 7.21603 0 7.57109C0 7.92614 0.287816 8.21396 0.642869 8.21396H10.377C10.7256 9.58369 12.1186 10.4115 13.4884 10.0629C14.3968 9.83166 15.1061 9.12234 15.3373 8.21396H17.3571C17.7122 8.21396 18 7.92614 18 7.57109C18 7.21603 17.7122 6.92822 17.3571 6.92822ZM12.8572 8.85679C12.1471 8.85679 11.5715 8.28115 11.5715 7.57109C11.5715 6.86102 12.1471 6.28538 12.8572 6.28538C13.5672 6.28538 14.1429 6.86102 14.1429 7.57109C14.1429 8.28115 13.5672 8.85679 12.8572 8.85679Z"
            />
            <path
                className="fill"
                style={{ fill: color }}
                d="M17.3571 19.7856H14.0516C13.7029 18.4159 12.3099 17.5881 10.9402 17.9367C10.0318 18.1679 9.32245 18.8773 9.09125 19.7856H0.642869C0.287816 19.7856 0 20.0735 0 20.4285C0 20.7835 0.287816 21.0713 0.642869 21.0713H9.09129C9.4399 22.4411 10.8329 23.2689 12.2027 22.9203C13.1111 22.6891 13.8204 21.9797 14.0516 21.0713H17.3571C17.7122 21.0713 18 20.7835 18 20.4285C18 20.0734 17.7122 19.7856 17.3571 19.7856ZM11.5714 21.7142C10.8614 21.7142 10.2857 21.1386 10.2857 20.4285C10.2857 19.7184 10.8614 19.1428 11.5714 19.1428C12.2815 19.1428 12.8571 19.7184 12.8571 20.4285C12.8571 21.1386 12.2815 21.7142 11.5714 21.7142Z"
            />
        </g>
        {
            checked
                ? (
                    <>
                        <circle cx="15" cy="8" r="7" fill="#9D3ADC" stroke="white" strokeWidth="2" />
                        <path
                            d="M17.9324 6.06193C17.8423 5.97936 17.6962 5.97936 17.6061 6.06193L13.8653 9.48953L12.3939 8.14134C12.3038 8.05876 12.1577 8.05876 12.0676 8.14134C11.9775 8.22392 11.9775 8.35779 12.0676 8.44038L13.7021 9.93809C13.7922 10.0206 13.9384 10.0206 14.0285 9.93809L17.9324 6.36096C18.0225 6.27838 18.0225 6.1445 17.9324 6.06193Z"
                            fill="white"
                            stroke="white"
                        />
                    </>
                )
                : null
        }
        <defs>
            <clipPath id="clip0">
                <rect width="18" height="18" fill="white" transform="translate(0 5)" />
            </clipPath>
        </defs>
    </svg>
)

Svg.propTypes = propTypes

export default Svg
