import { cleanUnavailableFields } from './01_defaultsConfig'
// Менять этот JSON файл нельзя! Это для задания конфига на этапе билда
import buildConfigOriginal from './02_buildConfig.json'
import { getDeepPathValueMap } from './utils'

// 1. Структура buildConfig должна совпадать с defaults. Узлы, которых нет в defaults - будут удаляться
// 2. Массивы и их содержимое не мержится а заменяется


const buildConfigDirty = getDeepPathValueMap(buildConfigOriginal)
export const buildConfig = cleanUnavailableFields(buildConfigDirty)
