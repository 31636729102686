import axios from 'axios'
import { createSlice, createSelector } from '@reduxjs/toolkit'

// включаем статус "загрузка" с задержкой, чтобы не мигал экран если грузится быстро. Эта констаната определяет задержку
const SET_LOADING_TIMOUT = 1000
export const STATION_SEARCH_BY_TYPE_PAGE_REDUCER_KEY = 'stationSearchByTypePage'

const stationSearchByTypePageSlice = createSlice({
    name: STATION_SEARCH_BY_TYPE_PAGE_REDUCER_KEY,
    initialState: {
        transportTypes: [],
        transportTypesLoaded: false,
        transportTypesError: false,
        transportTypesLoading: false,

        transportRoutesType: null,
        transportRoutes: {},
        transportRoutesLoaded: false,
        transportRoutesError: false,
        transportRoutesLoading: false,

        transportRouteId: null,
        transportRoute: [],
        transportRouteLoaded: false,
        transportRouteError: false,
        transportRouteLoading: false,
    },
    reducers: {
        clearErrors: (state, action) => {
            state.transportTypesError = false
            state.transportRoutesError = false
            state.transportRouteError = false
        },
        setLoadingStarted: (state, { payload }) => {
            state[payload] = true
        },
        setTransportTypes: (state, { payload }) => {
            state.transportTypesLoaded = true
            state.transportTypes = payload
            state.transportTypesError = false
            state.transportTypesLoading = false
        },
        clearTransportTypes: (state, action) => {
            state.transportTypesLoaded = false
            state.transportTypes = false
            state.transportTypesError = false
            state.transportTypesLoading = false
        },
        setTransportTypesError: (state, action) => {
            state.transportTypesError = true
            state.transportTypesLoading = false
        },
        setTransportRoutes: (state, { payload: { transportRoutesType, transportRoutes } }) => {
            state.transportRoutesType = transportRoutesType
            state.transportRoutes = transportRoutes
            state.transportRoutesLoaded = true
            state.transportRoutesError = false
            state.transportRoutesLoading = false
        },
        clearTransportRoutes: (state, action) => {
            state.transportRouteLoaded = false
            state.transportRoutesType = null
            state.transportRoutes = {}
            state.transportRoutesError = false
            state.transportRoutesLoading = false
        },
        setTransportRoutesError: (state, action) => {
            state.transportRoutesLoading = false
            state.transportRoutesError = true
        },
        setTransportRoute: (state, { payload: { transportRouteId, transportRoute } }) => {
            state.transportRouteId = transportRouteId
            state.transportRoute = transportRoute
            state.transportRouteLoaded = true
            state.transportRouteError = false
            state.transportRouteLoading = false
        },
        clearTransportRoute: (state, action) => {
            state.transportRouteLoaded = false
            state.transportRouteId = null
            state.transportRoute = []
            state.transportRouteError = false
            state.transportRouteLoading = false
        },
        setTransportRouteError: (state, action) => {
            state.transportRouteLoading = false
            state.transportRouteError = true
        },
    },
})

export const { clearErrors, setLoadingStarted } = stationSearchByTypePageSlice.actions

export const getStationSearchPage = state => state.pages[STATION_SEARCH_BY_TYPE_PAGE_REDUCER_KEY]
export const getTransportTypes = state => getStationSearchPage(state).transportTypes
export const getTransportLoaded = state => getStationSearchPage(state).transportTypesLoaded
export const getTransportTypesHash = createSelector([getTransportTypes], (transportTypes) => {
    const hash = {}

    transportTypes.forEach((item) => {
        hash[item.id] = item.type
    })

    return hash
})

export const getTransportRoutesType = state => getStationSearchPage(state).transportRoutesType
export const getTransportRoutes = state => getStationSearchPage(state).transportRoutes
export const getTransportRoutesLoaded = state => getStationSearchPage(state).transportRoutesLoaded
export const getTransportTypesError = state => getStationSearchPage(state).transportTypesError
export const getTransportRoutesError = state => getStationSearchPage(state).transportRoutesError
export const getTransportRouteError = state => getStationSearchPage(state).transportRouteError
export const getTransportTypesLoading = state => getStationSearchPage(state).transportTypesLoading
export const getTransportRoutesLoading = state => getStationSearchPage(state).transportRoutesLoading
export const getTransportRouteLoading = state => getStationSearchPage(state).transportRouteLoading
export const getLoading = createSelector(
    [getTransportTypesLoading, getTransportRoutesLoading, getTransportRouteLoading],
    (transportTypesLoading, transportRoutesLoading, transportRouteLoading) => (
        transportTypesLoading || transportRoutesLoading || transportRouteLoading
    ),
)

export const getGroupedRoutes = createSelector([getTransportRoutes], (transportRoutes) => {
    // группируем по номерам маршрутов
    const routes = {}
    Object.values(transportRoutes).forEach((item) => {
        if (typeof routes[item.number] === 'undefined') {
            routes[item.number] = []
        }

        routes[item.number].push({
            id: +item.id,
            number: item.number,
            from_station_name: item.from_station_name,
            to_station_name: item.to_station_name,
        })
    })

    // сортируем по номерам маршрутов
    const result = {}
    Object.keys(routes)
        .sort((a, b) => a.localeCompare(b))
        .forEach((item) => {
            result[item] = routes[item]
        })

    return result
})

export const getTransportRouteId = state => getStationSearchPage(state).transportRouteId
export const getTransportRoute = state => getStationSearchPage(state).transportRoute
export const getTransportRouteLoaded = state => getStationSearchPage(state).transportRouteLoaded
export const getTransportRouteInfo = createSelector(
    [getTransportRoutes, getTransportRouteId],
    (transportRoutes, transportRouteId) => {
        const route = transportRoutes[transportRouteId]
        if (route) {
            return route
        }

        return {}
    },
)

export const getRoutesReady = createSelector(
    [getTransportLoaded, getTransportRoutesLoaded, getTransportRoutesError, getLoading],
    (transportTypesLoaded, transportRoutesLoaded, hasError, loading) => (
        transportTypesLoaded && transportRoutesLoaded && !hasError && !loading
    ),
)

export const getRouteReady = createSelector(
    [getRoutesReady, getTransportRouteLoaded, getTransportRouteError, getLoading],
    (routesReady, transportRouteLoaded, hasError, loading) => (
        routesReady && transportRouteLoaded && !hasError && !loading
    ),
)

// включаем статус "загрузка" с задержкой, чтобы не мигал экран если грузится быстро
export const startLoading = loadingType => async (dispatch, getState) => {
    dispatch(clearErrors())
    return setTimeout(() => {
        dispatch(setLoadingStarted(loadingType))
    }, SET_LOADING_TIMOUT)
}

export const fetchTransportTypes = () => async (dispatch, getState) => {
    const state = getState().pages[STATION_SEARCH_BY_TYPE_PAGE_REDUCER_KEY]
    if (Array.isArray(state.transportTypes) && state.transportTypes.length) {
        return
    }

    const loadingTimeout = await dispatch(startLoading('transportTypesLoading'))

    try {
        const { data } = await axios.get('/getRouteTypes.php', {
            params: {},
        })

        if (!Array.isArray(data)) {
            throw new Error('Неверные данные с сервера')
        }

        const types = data.map(item => ({
            id: +item.id,
            type: item.type,
        }))

        dispatch(stationSearchByTypePageSlice.actions.setTransportTypes(types))
    } catch (err) {
        // eslint-disable-next-line no-console
        console.error('Ошибка получения типов транспорта', err)
        dispatch(stationSearchByTypePageSlice.actions.clearTransportTypes())
        dispatch(stationSearchByTypePageSlice.actions.setTransportTypesError())
    } finally {
        clearTimeout(loadingTimeout)
    }
}

export const fetchTransportRoutes = tid => async (dispatch, getState) => {
    const state = getState().pages[STATION_SEARCH_BY_TYPE_PAGE_REDUCER_KEY]

    // если тип транспорта другой чистим старое перед загрузкой
    if (tid && tid !== state.transportRoutesType) {
        dispatch(stationSearchByTypePageSlice.actions.clearTransportRoutes())
    } else {
        // если тип транспорта такой же ничего не грузим заново
        return
    }

    // грузим данные
    const loadingTimeout = await dispatch(startLoading('transportRoutesLoading'))

    try {
        const { data } = await axios.get('/getAllRoutes.php', {
            params: {
                tid,
            },
        })

        if (!Array.isArray(data)) {
            throw new Error('Неверные данные с сервера')
        }

        const routes = {}

        data.forEach((item) => {
            const id = +item.id
            routes[id] = {
                id,
                number: item.number,
                from_station_name: item.from_station_name,
                to_station_name: item.to_station_name,
            }
        })

        dispatch(
            stationSearchByTypePageSlice.actions.setTransportRoutes({
                transportRoutesType: tid,
                transportRoutes: routes,
            }),
        )
    } catch (err) {
        // eslint-disable-next-line no-console
        console.error('Ошибка получения маршрутов по типу транспорта', err)
        dispatch(stationSearchByTypePageSlice.actions.clearTransportRoutes())
        dispatch(stationSearchByTypePageSlice.actions.setTransportRoutesError())
    } finally {
        clearTimeout(loadingTimeout)
    }
}

export const fetchTransportRoute = rid => async (dispatch, getState) => {
    const state = getState().pages[STATION_SEARCH_BY_TYPE_PAGE_REDUCER_KEY]

    // если номер маршрута другой чистим старое перед загрузкой
    if (rid && rid !== state.transportRouteId) {
        dispatch(stationSearchByTypePageSlice.actions.clearTransportRoute())
    } else {
        // если номер маршрута такой же ничего не грузим заново
        return
    }

    const loadingTimeout = await dispatch(startLoading('transportRouteLoading'))

    try {
        const { data } = await axios.get('/getRouteStations.php', {
            params: {
                id: rid,
            },
        })

        if (!Array.isArray(data)) {
            throw new Error('Неверные данные с сервера')
        }

        dispatch(
            stationSearchByTypePageSlice.actions.setTransportRoute({
                transportRouteId: rid,
                transportRoute: data,
            }),
        )
    } catch (err) {
        // eslint-disable-next-line no-console
        console.error('Ошибка получения маршрута по id', err)
        dispatch(stationSearchByTypePageSlice.actions.clearTransportRoute())
        dispatch(stationSearchByTypePageSlice.actions.setTransportRouteError())
    } finally {
        clearTimeout(loadingTimeout)
    }
}

export default stationSearchByTypePageSlice.reducer
