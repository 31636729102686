import React from 'react'
import PropTypes from 'prop-types'
import ReduxDialog from 'react-modal'

import { IconClose } from '../../resources/icons'

import './Dialog.scss'

ReduxDialog.setAppElement('#root')

const propTypes = {
    opened: PropTypes.bool.isRequired,
    handleCloseDialog: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    className: PropTypes.string,
}

const Dialog = ({ children, opened, handleCloseDialog, title, className }) => {
    let dialogClassName = 'base-dialog'
    if (className && className.length) {
        dialogClassName += ` ${className}`
    }

    return (
        <ReduxDialog
            isOpen={opened}
            onRequestClose={handleCloseDialog}
            contentLabel={title}
            className={dialogClassName}
            overlayClassName="base-dialog-overlay"
        >
            <div className="content-wrapper">
                <div className="title-wrapper">
                    <div className="title">{title}</div>
                    <div onClick={handleCloseDialog} className="close-button" aria-hidden>
                        <IconClose />
                    </div>
                </div>
                {children}
            </div>
        </ReduxDialog>
    )
}

Dialog.propTypes = propTypes

export default Dialog
