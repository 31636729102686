import React from 'react'
import PropTypes from 'prop-types'


const propTypes = {
    color: PropTypes.string,
}

const Svg = ({ color }) => (
    <svg
        width="9"
        height="14"
        viewBox="0 0 9 14"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            className="fill"
            style={{ fill: color }}
            d="M5.46081 6.99704L0.223451 12.2346C0.079239 12.3785 -9.33351e-05 12.5709 -9.33531e-05 12.776C-9.3371e-05 12.9812 0.079239 13.1734 0.223451 13.3175L0.682376 13.7762C0.82636 13.9206 1.01883 14 1.22394 14C1.42904 14 1.62128 13.9206 1.76538 13.7762L8.00129 7.54042C8.14596 7.39587 8.22518 7.20272 8.22461 6.99738C8.22518 6.79114 8.14607 6.59821 8.00129 6.45354L1.77119 0.223771C1.62709 0.0794462 1.43485 -5.9358e-07 1.22963 -6.11521e-07C1.02452 -6.29452e-07 0.83228 0.0794461 0.688069 0.223771L0.229257 0.682471C-0.0692957 0.981023 -0.0692957 1.46704 0.229257 1.76548L5.46081 6.99704Z"
        />
    </svg>
)

Svg.propTypes = propTypes

export default Svg
