import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { IntlProvider } from 'react-intl'

import './App.scss'
import './transport-colors.scss'
import { LANG_MAP } from './resources/lang'
import { getError, getLocale, getThemeColor, getThemeModifier } from './store/commonSlice'
import Header from './widgets/Header'
import Footer from './widgets/Footer'
import Routes from './Routes'
import ErrorScreen from './components/StationSearch/ErrorScreen'

/* eslint react/prop-types: ["off"] */
function App({ locale, themeColor, themeModifier, error }) {
    /* Инициализируем озвучку на этапе загрузки приложения, необходимо для ios */
    useEffect(() => {
        if (window.SpeechSynthesisUtterance) {
            const message = new window.SpeechSynthesisUtterance('init')
            message.volume = 0
            speechSynthesis.speak(message)
        }
    }, [])

    useEffect(() => {
        document.body.className = `${themeColor} ${themeModifier}`
    }, [themeColor, themeModifier])

    return (
        <IntlProvider locale={locale} messages={LANG_MAP[locale].messages}>
            <Header />
            <div className="main-container">{error ? <ErrorScreen /> : <Routes />}</div>
            <Footer />
        </IntlProvider>
    )
}

export default connect(state => ({
    locale: getLocale(state),
    themeColor: getThemeColor(state),
    themeModifier: getThemeModifier(state),
    error: getError(state),
}))(App)
