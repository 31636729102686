import React from 'react'


const propTypes = {}

const Svg = () => (
    <svg
        width="60"
        height="60"
        viewBox="0 0 60 60"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M58.6267 51.9983L42.9845 36.3561C45.5327 32.5178 46.875 28.0729 46.875 23.4375C46.875 10.5141 36.3609 0 23.4375 0C10.5141 0 0 10.5141 0 23.4375C0 36.3609 10.5141 46.875 23.4375 46.875C28.073 46.875 32.5179 45.5326 36.3561 42.9846L40.6798 47.3082C40.6804 47.3088 40.6809 47.3094 40.6816 47.3101L51.9982 58.6267C52.8839 59.5123 54.0608 60 55.3125 60C56.5642 60 57.7411 59.5123 58.6263 58.627C59.5121 57.7419 60 56.5649 60 55.3125C60 54.0601 59.5121 52.8831 58.6267 51.9983ZM35.805 40.5149C35.805 40.515 35.8049 40.515 35.8048 40.5151C32.1875 43.1425 27.9111 44.5312 23.4375 44.5312C11.8064 44.5312 2.34375 35.0686 2.34375 23.4375C2.34375 11.8064 11.8064 2.34375 23.4375 2.34375C35.0686 2.34375 44.5312 11.8064 44.5312 23.4375C44.5312 27.9111 43.1425 32.1875 40.515 35.8049C39.2005 37.6158 37.6159 39.2005 35.805 40.5149ZM38.2636 41.5773C39.4765 40.5851 40.5852 39.4764 41.5773 38.2636L44.902 41.5883C43.8909 42.7816 42.7816 43.8911 41.5883 44.902L38.2636 41.5773ZM56.9694 56.9694C56.5264 57.4124 55.938 57.6562 55.3125 57.6562C54.687 57.6562 54.0986 57.4124 53.6556 56.9694L43.2499 46.5638C44.4367 45.5453 45.5453 44.4368 46.5636 43.25L56.9696 53.6561C57.4124 54.0983 57.6562 54.6865 57.6562 55.3125C57.6562 55.9385 57.4124 56.5267 56.9694 56.9694Z"
            fill="#9D3ADC"
        />
        <path
            d="M23.4375 4.6875C13.0986 4.6875 4.6875 13.0986 4.6875 23.4375C4.6875 33.7764 13.0986 42.1875 23.4375 42.1875C33.7764 42.1875 42.1875 33.7764 42.1875 23.4375C42.1875 13.0986 33.7764 4.6875 23.4375 4.6875ZM23.4375 39.8438C14.3911 39.8438 7.03125 32.4839 7.03125 23.4375C7.03125 14.3911 14.3911 7.03125 23.4375 7.03125C32.4839 7.03125 39.8438 14.3911 39.8438 23.4375C39.8438 32.4839 32.4839 39.8438 23.4375 39.8438Z"
            fill="#45C1FF"
        />
    </svg>
)

Svg.propTypes = propTypes

export default Svg
