import React from 'react'


export default () => (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M18.5021 3.44828H1.49785C0.670586 3.44828 0 4.11887 0 4.94613V15.0539C0 15.8811 0.670586 16.5518 1.49785 16.5518H18.5021C19.3294 16.5518 20 15.8812 20 15.0539V4.94613C20 4.11887 19.3294 3.44828 18.5021 3.44828Z"
            fill="#41479B"
        />
        <path
            d="M19.9793 4.69852C19.8612 3.98919 19.245 3.44825 18.5021 3.44825H18.1124L11.7241 7.63368V3.44829H8.27586V7.63372L1.88758 3.44829H1.49785C0.755 3.44829 0.138789 3.98919 0.0207422 4.69856L5.48082 8.27591H0V11.7242H5.48082L0.0207422 15.3015C0.138789 16.0108 0.755 16.5517 1.49785 16.5517H1.88758L8.27586 12.3663V16.5517H11.7241V12.3663L18.1124 16.5517H18.5021C19.245 16.5517 19.8612 16.0108 19.9793 15.3015L14.5192 11.7241H20V8.27583H14.5192L19.9793 4.69852Z"
            fill="#F5F5F5"
        />
        <path
            d="M11.0345 3.44828H8.96551V8.96551H0V11.0345H8.96551V16.5517H11.0345V11.0345H20V8.96551H11.0345V3.44828Z"
            fill="#FF4B55"
        />
        <path
            d="M0.968455 16.4552L8.25685 11.7241H6.99013L0.360291 16.0277C0.522517 16.2171 0.730994 16.3654 0.968455 16.4552Z"
            fill="#FF4B55"
        />
        <path
            d="M13.5308 11.7241H12.2641L19.3233 16.3064C19.5209 16.1766 19.6857 16.0014 19.8033 15.7956L13.5308 11.7241Z"
            fill="#FF4B55"
        />
        <path
            d="M0.158142 4.27636L6.31947 8.27586H7.58619L0.60447 3.74382C0.417009 3.88335 0.263611 4.06577 0.158142 4.27636Z"
            fill="#FF4B55"
        />
        <path
            d="M12.9909 8.27584L19.6329 3.96432C19.4689 3.77561 19.2588 3.62811 19.0197 3.5401L11.7242 8.27584H12.9909Z"
            fill="#FF4B55"
        />
        <rect x="0.5" y="3.5" width="19" height="13" rx="0.5" stroke="#333333" fill="none" />
    </svg>
)
