import React from 'react'
import PropTypes from 'prop-types'


const propTypes = {
    color: PropTypes.string,
}

const Svg = ({ color }) => (
    <svg
        width="26"
        height="16"
        viewBox="0 0 26 16"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect
            className="fill"
            style={{ fill: color }}
            width="24"
            height="2"
            rx="1"
        />
        <rect
            className="fill"
            style={{ fill: color }}
            y="7"
            width="24"
            height="2"
            rx="1"
        />
        <rect
            className="fill"
            style={{ fill: color }}
            y="14"
            width="24"
            height="2"
            rx="1"
        />
    </svg>
)

Svg.propTypes = propTypes

export default Svg
