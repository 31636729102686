import React from 'react'
import PropTypes from 'prop-types'


const propTypes = {
    color: PropTypes.string,
}

const Svg = ({ color }) => (
    <svg
        width="18"
        height="20"
        viewBox="0 0 18 20"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            className="fill"
            style={{ fill: color }}
            d="M10.9038 17.0663C9.91339 18.0568 8.59666 18.6022 7.19587 18.6022C4.30504 18.6022 1.95307 16.2502 1.95307 13.3591C1.95307 11.9582 2.49836 10.6412 3.48859 9.65089C3.76152 9.37797 3.76142 8.93545 3.4885 8.66253C3.21557 8.38951 2.77306 8.3896 2.50013 8.66262C1.24601 9.91692 0.555359 11.5848 0.555359 13.359C0.555359 17.0207 3.53425 19.9998 7.19587 19.9998C8.96994 19.9998 10.6378 19.309 11.8922 18.0544C12.1651 17.7815 12.165 17.3389 11.8921 17.0661C11.6192 16.7935 11.1766 16.7934 10.9038 17.0663Z"
        />
        <path
            className="fill"
            style={{ fill: color }}
            d="M16.7456 15.5338H14.7576L13.5268 12.1188C13.4269 11.8416 13.164 11.6569 12.8693 11.6569H7.68738V8.51894H12.5C12.8859 8.51894 13.1988 8.20614 13.1988 7.82009C13.1988 7.43404 12.8859 7.12123 12.5 7.12123H7.68738V4.96457C7.68738 4.57852 7.37448 4.26572 6.98853 4.26572C6.60257 4.26572 6.28967 4.57852 6.28967 4.96457V12.3557C6.28967 12.7417 6.60257 13.0545 6.98853 13.0545H12.3783L13.6091 16.4695C13.709 16.7467 13.9719 16.9314 14.2666 16.9314H16.7456C17.1315 16.9314 17.4444 16.6186 17.4444 16.2325C17.4444 15.8465 17.1317 15.5338 16.7456 15.5338Z"
        />
        <path
            className="fill"
            style={{ fill: color }}
            d="M7.01492 3.39531C7.95259 3.39531 8.71276 2.63552 8.71276 1.69719C8.71267 0.760447 7.95259 0 7.01492 0C6.07724 0 5.31726 0.760447 5.31726 1.69728C5.31726 2.63552 6.07724 3.39531 7.01492 3.39531Z"
        />
    </svg>
)

Svg.propTypes = propTypes

export default Svg
