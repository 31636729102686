import React from 'react'


const propTypes = {}

const Svg = () => (
    <svg
        width="60"
        height="60"
        viewBox="0 0 60 60"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M45 0H15C12.9325 0 11.25 1.68246 11.25 3.75C11.25 5.81754 12.9325 7.5 15 7.5H45C47.0675 7.5 48.75 5.81754 48.75 3.75C48.75 1.68246 47.0675 0 45 0ZM45 5.00004H15C14.31 5.00004 13.75 4.44 13.75 3.75C13.75 3.06 14.31 2.49996 15 2.49996H45C45.69 2.49996 46.25 3.06 46.25 3.75C46.25 4.44 45.69 5.00004 45 5.00004Z"
            fill="#9D3ADC"
        />
        <path
            d="M45 52.5H15C12.9325 52.5 11.25 54.1825 11.25 56.25C11.25 58.3175 12.9325 60 15 60H45C47.0675 60 48.75 58.3175 48.75 56.25C48.75 54.1825 47.0675 52.5 45 52.5ZM45 57.5H15C14.31 57.5 13.75 56.94 13.75 56.25C13.75 55.56 14.31 55 15 55H45C45.69 55 46.25 55.56 46.25 56.25C46.25 56.94 45.69 57.5 45 57.5Z"
            fill="#9D3ADC"
        />
        <path
            d="M25.8225 29.0601L19.2375 23.2976C17.34 21.6351 16.2501 19.2351 16.2501 16.7126V6.2501C16.2501 5.5601 15.69 5.00006 15 5.00006C14.31 5.00006 13.75 5.5601 13.75 6.2501V16.7126C13.75 19.9551 15.15 23.0426 17.5925 25.1801L23.1025 30L17.5925 34.8201C15.1499 36.9576 13.75 40.0451 13.75 43.2876V53.7501C13.75 54.4401 14.31 55.0001 15 55.0001C15.69 55.0001 16.2501 54.4401 16.2501 53.7501V43.2876C16.2501 40.7651 17.34 38.3651 19.2375 36.7026L25.8225 30.9401C26.095 30.7026 26.25 30.3601 26.25 30.0001C26.25 29.6401 26.095 29.2975 25.8225 29.0601Z"
            fill="#9D3ADC"
        />
        <path
            d="M46.25 16.7124V6.24992C46.25 5.55992 45.69 4.99988 45 4.99988C44.31 4.99988 43.75 5.55992 43.75 6.24992V16.7124C43.75 19.2349 42.6625 21.6349 40.7625 23.2974L34.1775 29.0599C33.905 29.2974 33.75 29.64 33.75 30C33.75 30.36 33.905 30.7025 34.1775 30.9399L40.7625 36.7024C42.66 38.3649 43.75 40.7649 43.75 43.2874V53.7499C43.75 54.4399 44.31 54.9999 45 54.9999C45.69 54.9999 46.25 54.4399 46.25 53.7499V43.2874C46.25 40.0423 44.85 36.9574 42.4076 34.8199L36.8975 30L42.4076 25.1799C44.85 23.0424 46.25 19.955 46.25 16.7124Z"
            fill="#9D3ADC"
        />
        <path
            d="M40.9749 47.97L30.9749 35.4699C30.5 34.8774 29.4974 34.8774 29.0225 35.4699L19.0225 47.97C18.7225 48.345 18.665 48.86 18.8725 49.2925C19.0825 49.725 19.52 49.9999 20 49.9999H40C40.48 49.9999 40.9175 49.7249 41.125 49.2925C41.3324 48.86 41.2749 48.345 40.9749 47.97ZM22.6025 47.5L29.9999 38.25L37.4 47.5H22.6025Z"
            fill="#45C1FF"
        />
        <path
            d="M36.1551 20.7726C35.9626 20.3051 35.5051 20.0001 35.0001 20.0001H25.0001C24.4952 20.0001 24.0377 20.3051 23.8451 20.7726C23.6501 21.24 23.7576 21.7751 24.1151 22.1351L29.1152 27.1351C29.3602 27.3776 29.6801 27.5002 30.0002 27.5002C30.3202 27.5002 30.6401 27.3777 30.8852 27.1351L35.8852 22.1351C36.2425 21.7776 36.3501 21.24 36.1551 20.7726ZM30.0001 24.4825L28.0176 22.5H31.9826L30.0001 24.4825Z"
            fill="#45C1FF"
        />
    </svg>
)

Svg.propTypes = propTypes

export default Svg
