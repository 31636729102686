import React from 'react'
import { useIntl } from 'react-intl'

import { IconNothing } from '../../resources/icons'

import './EmptyScreen.scss'

const EmptyScreen = () => {
    const intl = useIntl()

    return (
        <div className="favoritesPage-emptyScreen-wrapper">
            <div className="icon-wrapper">
                <IconNothing />
            </div>
            <div className="content-text">
                {intl.formatMessage({
                    id: 'pages.historyPage.EmptyScreen',
                })}
            </div>
        </div>
    )
}

export default EmptyScreen
