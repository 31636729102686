import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { IconCheck } from '../../resources/icons'

import './Checkbox.scss'


const propTypes = {
    className: PropTypes.string,
    checked: PropTypes.bool.isRequired,
}

const CheckBox = ({ className, checked }) => (
    <div className={classNames('checkbox', checked ? 'checked' : 'unchecked', className)}>
        {checked && <IconCheck />}
    </div>
)

CheckBox.propTypes = propTypes

export default CheckBox
