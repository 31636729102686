import React from 'react'


export default () => (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M18.5021 3H1.49785C0.670586 3 0 3.76765 0 4.71465V8H20V4.71465C20 3.76765 19.3294 3 18.5021 3Z"
            fill="#F5F5F5"
        />
        <path
            d="M0 15.2854C0 16.2323 0.670586 17 1.49785 17H18.5021C19.3294 17 20 16.2324 20 15.2854V12H0V15.2854Z"
            fill="#FF4B55"
        />
        <path d="M20 7.81602H0V12.1836H20V7.81602Z" fill="#41479B" />
        <rect x="0.5" y="3.5" width="19" height="13" rx="0.5" stroke="#333333" fill="none" />
    </svg>
)
