import React from 'react'


export default () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        xmlSpace="preserve"
        width="20px"
        height="20px"
        version="1.1"
        style={{ shapeRendering: 'geometricPrecision', textRendering: 'geometricPrecision', imageRendering: 'optimizeQuality', fillRule: 'evenodd', clipRule: 'evenodd' }}
        viewBox="0 0 1200 750"
    >
        <g>
            <rect fill="#FFED00" width="1200" height="750" />
            <polygon
                points="532,137 566,103 532,70 566,70 566,35 600,70 634,35 634,70 668,70 634,103 668,137 634,137 634,171 600,137 566,171 566,137"
                fill="#C70049"
            />
            <polygon
                points="566,120 583,103 566,86 583,86 583,70 600,86 617,70 617,86 634,86 617,103 634,120 617,120 617,137 600,120 583,137 583,120"
                fill="#FFED00"
            />
            <polygon
                points="579,337 489,247 455,247 404,298 451,345 476,345 511,310 476,277 455,298 468,310 476,302 485,310 468,328 438,298 476,259 579,362"
                fill="#C70049"
            />
            <polygon
                points="0,564 561,564 561,387 519,345 489,374 506,392 514,383 506,374 519,361 540,383 502,421 476,395 476,361 519,320 579,379 579,582 0,582"
                fill="#C70049"
            />
            <polygon
                points="0,600 591,600 591,332 498,238 498,205 548,154 596,200 596,226 561,259 527,226 548,205 561,217 553,226 561,235 578,217 548,188 511,226 600,316 690,226 652,188 622,217 639,235 647,226 639,217 652,205 673,226 639,259 604,226 604,200 652,154 702,205 702,238 609,332 609,600 1200,600 1200,750 0,750"
                fill="#C70049"
            />
            <polygon
                points="621,337 711,247 745,247 796,298 749,345 724,345 689,310 724,277 745,298 732,310 724,302 715,310 732,328 762,298 724,259 621,362"
                fill="#C70049"
            />
            <polygon
                points="1200,564 639,564 639,387 681,345 711,374 694,392 686,383 694,374 681,361 660,383 698,421 724,395 724,361 681,320 621,379 621,582 1200,582"
                fill="#C70049"
            />
            <polygon
                points="362,213 396,179 362,146 396,146 396,111 430,146 463,111 463,146 498,146 463,179 498,213 463,213 463,247 430,213 396,247 396,213"
                fill="#C70049"
            />
            <polygon
                points="396,196 413,179 396,162 413,162 413,146 430,162 447,146 447,162 463,162 447,179 463,196 447,196 447,213 430,196 413,213 413,196"
                fill="#FFED00"
            />
            <polygon
                points="702,213 736,179 702,146 736,146 736,111 770,146 804,111 804,146 838,146 804,179 838,213 804,213 804,247 770,213 736,247 736,213"
                fill="#C70049"
            />
            <polygon
                points="736,196 753,179 736,162 753,162 753,146 770,162 787,146 787,162 804,162 787,179 804,196 787,196 787,213 770,196 753,213 753,196"
                fill="#FFED00"
            />
        </g>
    </svg>
)
