import React from 'react'
import PropTypes from 'prop-types'


const propTypes = {
    color: PropTypes.string,
}

const Svg = ({ color }) => (
    <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            className="fill"
            style={{ fill: color }}
            d="M17.6628 16.0345L10.6283 9.00002L17.6628 1.96558C18.1123 1.51591 18.1123 0.786913 17.6627 0.337325C17.2131 -0.11234 16.484 -0.11234 16.0344 0.337325L9 7.37169L1.96557 0.337249C1.51599 -0.112416 0.786912 -0.112416 0.337248 0.337249C-0.112416 0.786914 -0.112416 1.51591 0.337248 1.96558L7.37168 9.00002L0.337248 16.0345C-0.112416 16.4841 -0.112416 17.2131 0.337248 17.6628C0.56208 17.8876 0.856765 18 1.15137 18C1.44606 18 1.74074 17.8876 1.96557 17.6628L9 10.6283L16.0344 17.6628C16.2593 17.8876 16.5539 18 16.8486 18C17.1433 18 17.438 17.8876 17.6628 17.6628C18.1124 17.2131 18.1124 16.4841 17.6628 16.0345Z"
        />
    </svg>
)

Svg.propTypes = propTypes

export default Svg
