import React from 'react'
import PropTypes from 'prop-types'


const propTypes = {
    color: PropTypes.string,
}

const Svg = ({ color }) => (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clipPath="url(#clip-IconGlasses)">
            <path
                className="fill"
                style={{ fill: color }}
                d="M19.9751 11.0026L17.6337 2.80777C17.5618 2.55625 17.3319 2.38281 17.0703 2.38281H13.5547C13.2311 2.38281 12.9688 2.64516 12.9688 2.96875C12.9688 3.29234 13.2311 3.55469 13.5547 3.55469H16.6284L18.6287 10.5561C17.9185 10.5374 16.8268 10.5272 15.6777 10.5854C12.4051 10.7513 11.3241 11.3322 10.8735 11.8271C10.5958 11.7822 10.2969 11.7578 10 11.7578C9.70316 11.7578 9.40426 11.7822 9.1266 11.8271C8.67602 11.3322 7.595 10.7513 4.32238 10.5854C3.17324 10.5272 2.0816 10.5374 1.37133 10.5561L3.37164 3.55469H6.44531C6.76891 3.55469 7.03125 3.29234 7.03125 2.96875C7.03125 2.64516 6.76891 2.38281 6.44531 2.38281H2.92969C2.66809 2.38281 2.43816 2.55625 2.36629 2.80777L0.0249219 11.0026C0.010625 11.0499 0 11.1146 0 11.1719V13.5156C0 15.7772 1.83996 17.6172 4.10156 17.6172H5.50742C7.28258 17.6172 8.84469 16.4985 9.39457 14.8336C9.63086 14.118 9.70801 13.4869 9.62687 12.9457C9.74996 12.9353 9.8757 12.9297 10 12.9297C10.1243 12.9297 10.25 12.9353 10.3731 12.9457C10.292 13.4869 10.3691 14.118 10.6054 14.8336C11.1553 16.4985 12.7174 17.6172 14.4926 17.6172H15.8984C18.16 17.6172 20 15.7772 20 13.5156V11.1719C20 11.1226 19.9918 11.0581 19.9751 11.0026ZM8.28184 14.4661C7.89082 15.6499 6.77586 16.4453 5.50742 16.4453H4.10156C2.48613 16.4453 1.17188 15.1311 1.17188 13.5156V11.7347C1.84004 11.7135 3.02836 11.6932 4.26301 11.7558C7.6375 11.9268 8.22027 12.5479 8.30281 12.6707C8.6198 13.1421 8.46637 13.9072 8.28184 14.4661ZM18.8281 13.5156C18.8281 15.1311 17.5139 16.4453 15.8984 16.4453H14.4926C13.2241 16.4453 12.1091 15.6499 11.7182 14.4661C11.5336 13.9072 11.3802 13.1421 11.6971 12.6707C11.7796 12.5481 12.3613 11.9279 15.7282 11.7562C16.7233 11.7055 17.8227 11.703 18.8281 11.7347V13.5156H18.8281Z"
            />
        </g>
        <defs>
            <clipPath id="clip-IconGlasses">
                <rect width="20" height="20" fill="white" />
            </clipPath>
        </defs>
    </svg>
)

Svg.propTypes = propTypes

export default Svg
