import React, { useCallback, useState } from 'react'
import { connect } from 'react-redux'
import { useIntl } from 'react-intl'

import { IconArrowDown } from '../../../resources/icons'
import { getTableHeaderVisible } from '../../../store/commonSlice'
import { getTransportColumnDescView } from '../../../store/stationForecastPageSlice'

import TransportColumnDescViewDialog from './TransportColumnDescViewDialog'
import './Header.scss'


const typesViewLabels = {
    whenNextBus: 'pages.stationForecasts.title.menu.following',
    whereBusNow: 'pages.stationForecasts.title.menu.current',
    busDirection: 'pages.stationForecasts.title.menu.direction',
}

/* eslint react/prop-types: ["off"] */
const StationForecastsTitle = ({ tableHeaderVisible, transportColumnDescView }) => {
    const intl = useIntl()

    const [transportColumnDescViewDialogOpened, setTransportColumnDescViewDialogOpened] = useState(false)

    const transportColumnDescViewDialogOpenedToggle = useCallback(() => {
        setTransportColumnDescViewDialogOpened(!transportColumnDescViewDialogOpened)
    }, [transportColumnDescViewDialogOpened, setTransportColumnDescViewDialogOpened])

    return (
        <>
            <div className="sfp-grid-header-wrapper">
                {tableHeaderVisible && (
                    <div className="title-text-menu">
                        <div className="button disabled">
                            {intl.formatMessage({ id: 'pages.stationForecasts.title.menu.mashrut' })}
                        </div>
                        <div className="button disabled">
                            {intl.formatMessage({ id: 'pages.stationForecasts.title.menu.forecast' })}
                        </div>
                        <div
                            onClick={transportColumnDescViewDialogOpenedToggle}
                            aria-hidden
                            className="button"
                        >
                            {intl.formatMessage({ id: typesViewLabels[transportColumnDescView] })}
                            {' '}
                            <IconArrowDown />
                        </div>
                    </div>
                )}
            </div>

            {tableHeaderVisible && (
                <TransportColumnDescViewDialog
                    opened={transportColumnDescViewDialogOpened}
                    handleCloseDialog={transportColumnDescViewDialogOpenedToggle}
                />
            )}
        </>
    )
}

export default connect(state => ({
    tableHeaderVisible: getTableHeaderVisible(state),
    transportColumnDescView: getTransportColumnDescView(state),
}))(StationForecastsTitle)
