import React from 'react'
import PropTypes from 'prop-types'


const propTypes = {
    color: PropTypes.string,
}

const Svg = ({ color }) => (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            className="fill"
            style={{ fill: color }}
            d="M17.0745 2.92551C13.1738 -0.975139 6.82666 -0.975524 2.92563 2.92551C-0.975402 6.82654 -0.975017 13.1733 2.92563 17.0743C6.82628 20.9754 13.1734 20.9754 17.0745 17.0743C20.9751 13.1733 20.9751 6.82615 17.0745 2.92551ZM13.9453 13.9452C13.6448 14.2457 13.1577 14.2457 12.8571 13.9452L10 11.0881L7.00713 14.081C6.7066 14.3816 6.21946 14.3816 5.91893 14.081C5.61841 13.7805 5.61841 13.2934 5.91893 12.9928L8.91185 9.99993L6.05477 7.14284C5.75424 6.84231 5.75424 6.35478 6.05477 6.05464C6.35529 5.75412 6.84244 5.75412 7.14296 6.05464L10 8.91173L12.7209 6.19086C13.0214 5.89034 13.5086 5.89034 13.8091 6.19086C14.1096 6.49138 14.1096 6.97853 13.8091 7.27905L11.0882 9.99993L13.9453 12.857C14.2459 13.1575 14.2459 13.6447 13.9453 13.9452Z"
        />
    </svg>
)

Svg.propTypes = propTypes

export default Svg
