import messagesRu from './ru.json'
import messagesEn from './en.json'
import messagesCv from './ru-cv.json'


export const LANG_MAP = {
    ru: {
        locale: 'ru',
        shortLabel: 'ru',
        label: 'Русский',
        flag: 'russia',
        messages: messagesRu,
    },
    en: {
        locale: 'en',
        shortLabel: 'en',
        label: 'English',
        flag: 'england',
        messages: messagesEn,
    },
    'ru-cv': {
        locale: 'ru-cv',
        shortLabel: 'cv',
        label: 'Чӑвашла',
        flag: 'chuvash',
        messages: messagesCv,
    },
}
