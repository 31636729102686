import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { connect } from 'react-redux'
import { Link, useRouteMatch } from 'react-router-dom'

import { useGetQueryParams } from '../../services/useGetQuery'
import {
    fetchTransportTypes,
    fetchTransportRoutes,
    fetchTransportRoute,
    getRoutesReady,
    getRouteReady,
    getTransportTypesHash,
    getTransportRouteInfo,
    getLoading,
    getTransportTypesError,
    getTransportRoutesError,
    getTransportRouteError,
} from '../../store/stationSearchByTypePageSlice'
import StationSearchPageTitle from '../../components/StationSearch/PageTitle'
import LoadingScreen from '../../components/StationSearch/LoadingScreen'
import ErrorScreen from '../../components/StationSearch/ErrorScreen'
import { IconBack } from '../../resources/icons'

import TypeSelector from './TypeSelector'
import RoutesList from './RoutesList'
import RouteList from './RouteList'
import './StationSearchByTypePage.scss'

/* eslint react/prop-types: ["off"] */
const StationSearchPageByType = ({
    routesReady,
    routeReady,
    loading,
    transportTypesError,
    transportRoutesError,
    transportRouteError,
    fetchTransportTypes,
    fetchTransportRoutes,
    fetchTransportRoute,
    transportTypesHash,
    transportRouteInfo,
}) => {
    const intl = useIntl()
    const { tid, rid } = useGetQueryParams()
    const routeMatch = useRouteMatch()

    useEffect(() => {
        fetchTransportTypes()
    }, [fetchTransportTypes, tid, rid])

    useEffect(() => {
        fetchTransportRoutes(+tid)
    }, [tid, fetchTransportRoutes])

    useEffect(() => {
        fetchTransportRoute(+rid)
    }, [rid, fetchTransportRoute])

    let transportType = null
    if (tid > 0 && routesReady) {
        transportType = transportTypesHash[tid]
    }

    return (
        <>
            <StationSearchPageTitle
                title={intl.formatMessage({ id: 'pages.stationSearchByType.title' })}
                headTitle={intl.formatMessage({ id: 'pages.stationSearchByType.htmlHead.title' })}
            />
            {loading && (
                <LoadingScreen message={intl.formatMessage({ id: 'pages.stationSearchByType.LoadingScreen' })} />
            )}

            {!loading && (
                <>
                    {/* Подзаголовок - Тип транспорта */}
                    {tid > 0 && routesReady && (
                        <div className="stationSearchByTypePage-subTitleWrapper">
                            <Link to={routeMatch.path} className="back-button">
                                <IconBack />
                            </Link>
                            <div className={`type-title-badge ${transportType}`}>
                                {intl.formatMessage({
                                    id: `pages.stationSearchByType.selector.shortcut.${transportType}`,
                                })}
                            </div>
                            <div className="type-title">
                                {intl.formatMessage({
                                    id: `pages.stationSearchByType.selector.title.${transportType}`,
                                })}
                            </div>
                        </div>
                    )}
                    {/* Подзаголовок - Номер маршрута */}
                    {tid > 0 && rid > 0 && routeReady && (
                        <div className="stationSearchByTypePage-subTitleWrapper">
                            <Link to={`${routeMatch.path}?tid=${tid}`} className="back-button">
                                <IconBack />
                            </Link>
                            <div className={`type-title-badge ${transportType}`}>{transportRouteInfo.number}</div>
                            <div className="type-title">
                                {transportRouteInfo.from_station_name}
                                &nbsp;-&nbsp;
                                {transportRouteInfo.to_station_name}
                            </div>
                        </div>
                    )}

                    {/* Списки для выбора - Тип транспорта */}
                    {typeof tid === 'undefined' && (
                        <>
                            {transportTypesError && (
                                <ErrorScreen
                                    message={intl.formatMessage({
                                        id: 'pages.stationSearchByType.ErrorScreen',
                                    })}
                                />
                            )}
                            {!transportTypesError && <TypeSelector />}
                        </>
                    )}

                    {/* Списки для выбора - Маршруты */}
                    {tid > 0 && typeof rid === 'undefined' && (
                        <>
                            {transportRoutesError && (
                                <ErrorScreen
                                    message={intl.formatMessage({
                                        id: 'pages.stationSearchByType.ErrorScreen',
                                    })}
                                />
                            )}
                            {routesReady && <RoutesList typeId={tid} transportType={transportType} />}
                        </>
                    )}
                    {/* Списки для выбора - Остановки */}
                    {tid > 0 && rid > 0 && (
                        <>
                            {transportRouteError && (
                                <ErrorScreen
                                    message={intl.formatMessage({
                                        id: 'pages.stationSearchByType.ErrorScreen',
                                    })}
                                />
                            )}
                            {routeReady && <RouteList transportType={transportType} />}
                        </>
                    )}
                </>
            )}
        </>
    )
}

export default connect(
    state => ({
        routesReady: getRoutesReady(state),
        routeReady: getRouteReady(state),
        transportTypesHash: getTransportTypesHash(state),
        transportRouteInfo: getTransportRouteInfo(state),
        loading: getLoading(state),
        transportTypesError: getTransportTypesError(state),
        transportRoutesError: getTransportRoutesError(state),
        transportRouteError: getTransportRouteError(state),
    }),
    {
        fetchTransportTypes,
        fetchTransportRoutes,
        fetchTransportRoute,
    },
)(StationSearchPageByType)
