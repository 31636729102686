import React from 'react'
import PropTypes from 'prop-types'


const propTypes = {
    color: PropTypes.string,
}

const Svg = ({ color }) => (
    <svg
        width="9"
        height="14"
        viewBox="0 0 9 14"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            className="fill"
            style={{ fill: color }}
            d="M2.7638 7.00296L8.00116 1.76536C8.14537 1.62149 8.2247 1.42914 8.2247 1.22403C8.2247 1.01881 8.14537 0.826568 8.00116 0.68247L7.54223 0.223771C7.39825 0.0794459 7.20578 -1.2599e-06 7.00067 -1.22404e-06C6.79557 -1.18817e-06 6.60332 0.079446 6.45923 0.223771L0.223321 6.45958C0.0786545 6.60413 -0.000564867 6.79728 4.27379e-06 7.00262C-0.000564795 7.20886 0.0785408 7.40179 0.223321 7.54646L6.45342 13.7762C6.59752 13.9206 6.78976 14 6.99498 14C7.20009 14 7.39233 13.9206 7.53654 13.7762L7.99536 13.3175C8.29391 13.019 8.29391 12.533 7.99536 12.2345L2.7638 7.00296Z"
        />
    </svg>
)

Svg.propTypes = propTypes

export default Svg
