import React from 'react'
import PropTypes from 'prop-types'

import IconTypeVolume from './IconTypeVolume'
import IconTypeNotVolume from './IconTypeNotVolume'
import IconTypePlace from './IconTypePlace'


const propTypes = {
    iconType: PropTypes.oneOf(['volume', 'not-volume', 'place']),
}

const IconType = ({ iconType }) => {
    switch (iconType) {
        case 'volume':
            return <IconTypeVolume />

        case 'not-volume':
            return <IconTypeNotVolume />

        case 'place':
            return <IconTypePlace />

        default:
            return null
    }
}

IconType.propTypes = propTypes

export default IconType
