import React from 'react'
import PropTypes from 'prop-types'

const propTypes = {
    color: PropTypes.string,
}

const Svg = ({ color }) => (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M7 18.2857L5.38462 28L14 24L22.6154 28L22.0769 18.2857L28 10.8571L18.8462 9.14286L13.4615 0L9.69231 8.57143L0 10.8571L7 18.2857Z"
            fill="#FDCC1F"
        />
    </svg>
)

Svg.propTypes = propTypes

export default Svg
