import React from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'

import { IconWarning } from '../../../resources/icons'

import './ErrorScreen.scss'


const propTypes = {
    message: PropTypes.string,
}

const ErrorScreen = ({ message }) => {
    const intl = useIntl()

    if (!message) {
        message = intl.formatMessage({
            id: 'default.ErrorScreen',
        })
    }

    return (
        <div className="ss-error-screen-wrapper">
            <div className="icon-wrapper">
                <IconWarning />
            </div>
            <div className="content-text">
                {message}
            </div>
        </div>
    )
}

ErrorScreen.propTypes = propTypes

export default ErrorScreen
