import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { IconDot } from '../../resources/icons'

import './RadioButton.scss'


const propTypes = {
    className: PropTypes.string,
    checked: PropTypes.bool.isRequired,
}

const RadioButton = ({ className, checked }) => (
    <div className={classNames('radiobutton', checked ? 'checked' : 'unchecked', className)}>
        {checked && <IconDot />}
    </div>
)

RadioButton.propTypes = propTypes

export default RadioButton
