import React, { useState, useCallback, useEffect } from 'react'
import { connect } from 'react-redux'
import { useIntl } from 'react-intl'

import { getLocale } from '../../store/commonSlice'
import { IconDrawer, IconSetup } from '../../resources/icons'
import Drawer from '../Drawer'

import SettingsDialog from './SettingsDialog'
import './Header.scss'

/* eslint react/prop-types: ["off"] */
const Header = ({ locale, path }) => {
    const intl = useIntl()

    // Lang dialog
    const [settingsDialogOpened, setSettingsDialogOpened] = useState(false)

    const handleOpenSettingsDialog = useCallback(() => {
        setSettingsDialogOpened(true)
    }, [setSettingsDialogOpened])

    const handleCloseSettingsDialog = useCallback(() => {
        setSettingsDialogOpened(false)
    }, [setSettingsDialogOpened])

    // Drawer
    const [drawerOpened, setDrawerOpened] = useState(false)
    const openDrawer = useCallback(() => {
        setDrawerOpened(true)
    }, [setDrawerOpened])

    const closeDrawer = useCallback(() => {
        setDrawerOpened(false)
    }, [setDrawerOpened])

    // Открываем меню для пустой главной страницы
    useEffect(() => {
        if (path === '/') {
            setDrawerOpened(true)
        }
    }, [path, setDrawerOpened])

    return (
        <>
            <div className="header-menu-wrapper">
                <div onClick={openDrawer} className="menu-drawer" aria-hidden>
                    <IconDrawer />
                </div>

                <div className="menu-settings" aria-hidden onClick={handleOpenSettingsDialog}>
                    <IconSetup />
                    <div className="menu-settings-label">
                        {intl.formatMessage({
                            id: 'pages.stationForecasts.footer.menu.settings',
                        })}
                    </div>
                </div>
            </div>

            <SettingsDialog opened={settingsDialogOpened} handleCloseDialog={handleCloseSettingsDialog} />
            {drawerOpened && <Drawer opened={drawerOpened} closeDrawer={closeDrawer} />}
        </>
    )
}

export default connect(state => ({
    locale: getLocale(state),
    path: state.router.location.pathname,
}))(Header)
