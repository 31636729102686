import React from 'react'
import PropTypes from 'prop-types'

const propTypes = {
    color: PropTypes.string,
}

const Svg = ({ color }) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M23.2315 0.00178824H0.768535C0.564842 0.00226069 0.369619 0.0890844 0.225586 0.243259C0.0815534 0.397434 0.000441372 0.606404 0 0.824439V19.6882C0 20.1406 0.345841 20.5108 0.768535 20.5108H15.0215V20.5073H23.2315C23.6542 20.5073 24 20.138 24 19.6846V0.822651C23.9989 0.604832 23.9176 0.396269 23.7737 0.242246C23.6298 0.0882231 23.435 0.00117425 23.2315 0V0.00178824ZM22.919 16.0632C22.919 16.4316 22.6367 16.7338 22.2925 16.7338H1.61726C1.45124 16.7333 1.29213 16.6625 1.17473 16.5368C1.05733 16.4112 0.991182 16.2409 0.990741 16.0632V1.75618C0.990741 1.38688 1.27226 1.08554 1.61726 1.08554H22.2925C22.6367 1.08554 22.919 1.38688 22.919 1.75618V16.0632ZM16.1994 23.5189C16.1718 23.4876 16.1476 23.4697 16.1426 23.4634L16.1342 23.4599L16.1275 23.4563C16.1275 23.4563 16.0891 23.4384 16.0273 23.4008C15.9379 23.3508 15.7917 23.2721 15.6564 23.1889C15.5398 23.1201 15.4299 23.0393 15.3281 22.9475C15.3074 22.8986 15.2912 22.8477 15.2796 22.7955C15.2103 22.5093 15.146 21.946 15.0959 21.4184H8.83063C8.81142 21.6241 8.79053 21.8369 8.76714 22.0372C8.74356 22.2518 8.71235 22.4654 8.67358 22.6774C8.65741 22.7664 8.63421 22.8538 8.60425 22.9385L8.5984 22.9502C8.51987 23.036 8.31437 23.1701 8.13644 23.2721C8.04706 23.3275 7.96102 23.3704 7.90003 23.4026L7.79979 23.4563L7.79478 23.4607L7.78809 23.4643C7.77974 23.4715 7.75635 23.4822 7.72962 23.518C7.69429 23.5653 7.6746 23.6238 7.67365 23.6843C7.67616 23.6861 7.67114 23.7174 7.66947 23.763C7.66947 23.8014 7.67532 23.8685 7.72628 23.9284C7.7764 23.9821 7.84574 23.9999 7.90755 23.9999H16.0239C16.0901 24.0014 16.1543 23.9758 16.2036 23.9284C16.2428 23.8834 16.2639 23.8236 16.262 23.7621C16.2604 23.7174 16.2537 23.6852 16.2553 23.6834C16.2551 23.6232 16.2363 23.5647 16.2019 23.5171L16.1994 23.5189Z"
            fill="white"
        />
    </svg>
)

Svg.propTypes = propTypes

export default Svg
