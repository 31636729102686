import throttle from 'lodash-es/throttle'

import * as userConfig from '../resources/config/03_userConfig'
import { updateQuery, getUpdatedQueryParams } from '../resources/config/04_queryConfig'
import { history } from '../services/history'


const SEC = 1000

const SAVE_BATCH_TIME = SEC

export const saveUserConfigMiddleware = (store) => {
    const saveUserConfig = throttle(
        () => {
            const state = store.getState()

            userConfig.setToLocalStorage(state)
            updateQuery(state)
        },
        SAVE_BATCH_TIME,
        { leading: false },
    )

    // Патчим query параметры любого перехода, чтоб не потерять конфиг
    history.block((location, action) => {
        // Этот метод вызывается до перехода и изменение query параметров влияет на параметры перехода
        const newSearch = getUpdatedQueryParams(store.getState(), location.search)

        if (newSearch) {
            location.search = `?${newSearch}`
        }

        return undefined
    })

    return next => (action) => {
        next(action)

        saveUserConfig()
    }
}
