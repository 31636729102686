import React from 'react'
import { useIntl } from 'react-intl'

import { IconSearch } from '../../resources/icons'

import './EmptyScreen.scss'


const EmptyScreen = () => {
    const intl = useIntl()

    return (
        <div className="stationSearchByKeywordPage-emptyScreen-wrapper">
            <div className="icon-wrapper">
                <IconSearch />
            </div>
            <div className="content-text">
                {intl.formatMessage({
                    id: 'pages.stationSearchByKeyword.EmptyScreen',
                })}
            </div>
        </div>
    )
}

export default EmptyScreen
