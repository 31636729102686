import axios from 'axios'
import { createSelector, createSlice } from '@reduxjs/toolkit'

import { localStorageGetItem, localStorageSetItem } from '../services/localStorage'
import { arrayDiff } from '../services/utils'
import config from '../resources/config'

const HISTORY_LOCAL_STORAGE_KEY = 'BUS_HISTORY'

export const HISTORY_PAGE_REDUCER_KEY = 'historyPage'

const historyPageSlice = createSlice({
    name: HISTORY_PAGE_REDUCER_KEY,
    initialState: {
        historyLength: config.pages[HISTORY_PAGE_REDUCER_KEY].stationHistoryLength,
        list: [],

        stations: {},
        stationsError: false,
    },
    reducers: {
        setHistory: (state, { payload }) => {
            state.list = payload
        },
        addToHistory: (state, { payload: stationId }) => {
            let newList = state.list.slice(0)
            const index = state.list.indexOf(stationId)
            if (index === -1) {
                newList.unshift(stationId)
            } else {
                newList = [stationId, ...state.list.slice(0, index), ...state.list.slice(index + 1)]
            }

            state.list = newList.slice(0, state.historyLength)
        },
        addToStations: (state, { payload: stations }) => {
            stations.forEach((station) => {
                state.stations[station.id] = station
            })
        },
        removeFromStations: (state, { payload: id }) => {
            delete state.stations[id]
        },
        setStationsErrors: (state, { payload }) => {
            state.stationsError = payload
        },
    },
})

export const getHistoryPage = state => state.pages[HISTORY_PAGE_REDUCER_KEY]
export const getHistory = state => getHistoryPage(state).list
export const getHistoryStations = state => getHistoryPage(state).stations
export const getHasStationsError = state => getHistoryPage(state).stationsError
export const getHistoryStationsList = createSelector([getHistory, getHistoryStations], (history, stations) => {
    const result = []

    history.forEach((id) => {
        if (typeof stations[id] !== 'undefined') {
            result.push(stations[id])
        }
    })

    return result
})

export const addToHistory = stationId => (dispatch, getState) => {
    dispatch(historyPageSlice.actions.addToHistory(+stationId))
    const history = getHistory(getState())
    localStorageSetItem(HISTORY_LOCAL_STORAGE_KEY, history)
    dispatch(unloadExcessStations())
}

export const initHistory = stationId => (dispatch) => {
    const history = localStorageGetItem(HISTORY_LOCAL_STORAGE_KEY)
    if (history && Array.isArray(history)) {
        dispatch(historyPageSlice.actions.setHistory(history))
    }
}

export const loadHistoryStations = () => async (dispatch, getState) => {
    dispatch(historyPageSlice.actions.setStationsErrors(false))

    const state = getState()
    const history = getHistory(state)
    const stations = getHistoryStations(state)

    const needToLoad = arrayDiff(
        history,
        Object.keys(stations).map(item => +item),
    )

    if (needToLoad.length) {
        try {
            const { data } = await axios.get('/getStationInfo.php', { params: { sids: needToLoad.join(',') } })
            data.forEach((item, i) => {
                item.id = needToLoad[i]
            })

            dispatch(historyPageSlice.actions.addToStations(data))
        } catch (err) {
            // eslint-disable-next-line no-console
            console.error('Ошибка получения данных об остановках', err)
            dispatch(historyPageSlice.actions.setStationsErrors(true))
        }
    }
}

export const unloadExcessStations = () => (dispatch, getState) => {
    const state = getState()
    const history = getHistory(state)
    const stations = getHistoryStations(state)

    const needToUnload = arrayDiff(
        Object.keys(stations).map(item => +item),
        history,
    )

    if (needToUnload.length) {
        needToUnload.forEach(id => dispatch(historyPageSlice.actions.removeFromStations(id)))
    }
}

export default historyPageSlice.reducer
