import React, { useCallback, useState } from 'react'

import { IconArrowDown } from '../../resources/icons'

import './Accordition.scss'

/* eslint react/prop-types: ["off"] */
const Accordition = ({ title, children, titleClassName }) => {
    const [isOpened, setIsOpened] = useState(false)

    const toggleOpened = useCallback(() => {
        setIsOpened(val => !val)
    }, [])

    const titleClassNameSum = `accordition-title ${titleClassName} ${isOpened ? 'opened' : ''}`

    return (
        <div className="accordition">
            <div onClick={toggleOpened} aria-hidden className={titleClassNameSum}>
                {title}
                <div className="accordition-icon">
                    <IconArrowDown />
                </div>
            </div>
            {isOpened && <>{children}</>}
        </div>
    )
}

export default Accordition
