import React from 'react'


const Svg = () => (
    <svg width="144" height="204" viewBox="0 0 144 204" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M82.5 0C37.0104 0 0 36.9357 0 82.3335C0 95.9619 3.41424 109.475 9.90481 121.46L77.9885 244.347C78.8948 245.985 80.622 247 82.5 247C84.378 247 86.1052 245.985 87.0116 244.347L155.12 121.419C161.586 109.475 165 95.9614 165 82.333C165 36.9357 127.99 0 82.5 0ZM82.5 123.5C59.7552 123.5 41.2502 105.032 41.2502 82.3335C41.2502 59.6346 59.7552 41.167 82.5 41.167C105.245 41.167 123.75 59.6346 123.75 82.3335C123.75 105.032 105.245 123.5 82.5 123.5Z"
            fill="white"
            fillOpacity="0.1"
        />
    </svg>
)

export default Svg
