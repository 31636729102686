import React from 'react'
import { connect } from 'react-redux'
import isArray from 'lodash-es/isArray'

import { getTransportsIconsVisible, getTransportsNumberVisible } from '../../../store/commonSlice'
import { getTransportColumnDescView, getVisibleForecasts } from '../../../store/stationForecastPageSlice'

import Header from './Header'
import ForecastRow from './ForecastRow'

/* eslint react/prop-types: ["off"] */
const Grid = ({ transportsIconsVisible, transportsNumberVisible, transportColumnDescView, visibleForecasts }) => (
    <>
        <Header />

        {isArray(visibleForecasts)
            && visibleForecasts.length > 0
            && visibleForecasts.map(routeForecasts => (
                <ForecastRow
                    key={routeForecasts.rid}
                    transportsIconsVisible={transportsIconsVisible}
                    transportsNumberVisible={transportsNumberVisible}
                    transportColumnDescView={transportColumnDescView}
                    routeForecasts={routeForecasts}
                />
            ))}
    </>
)

export default connect(state => ({
    transportsIconsVisible: getTransportsIconsVisible(state),
    transportsNumberVisible: getTransportsNumberVisible(state),
    transportColumnDescView: getTransportColumnDescView(state),
    visibleForecasts: getVisibleForecasts(state),
}))(Grid)
