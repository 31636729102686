import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { useIntl } from 'react-intl'

import StationSearchPageTitle from '../../components/StationSearch/PageTitle'
import StationsList from '../../components/StationSearch/StationsList'
import {
    getFavorites,
    getFavoritesStationsList,
    getHasStationsError,
    loadFavoritesStations,
} from '../../store/favoritesPageSlice'
import LoadingScreen from '../../components/StationSearch/LoadingScreen'
import ErrorScreen from '../../components/StationSearch/ErrorScreen'

import EmptyScreen from './EmptyScreen'

/* eslint react/prop-types: ["off"] */
const FavoritesPage = ({ favorites, stations, hasError, loadFavoritesStations }) => {
    const intl = useIntl()

    useEffect(() => {
        loadFavoritesStations()
    }, [favorites, loadFavoritesStations])

    const hasItems = Array.isArray(favorites) && favorites.length > 0
    const isLoaded = stations.length === favorites.length

    return (
        <>
            <StationSearchPageTitle
                title={intl.formatMessage({
                    id: 'pages.favoritesPage.title',
                })}
                headTitle={intl.formatMessage({
                    id: 'pages.favoritesPage.htmlHead.title',
                })}
            />
            {!hasItems ? (
                <EmptyScreen />
            ) : (
                <>
                    {hasError ? (
                        <ErrorScreen
                            message={intl.formatMessage({
                                id: 'pages.favoritesPage.ErrorScreen',
                            })}
                        />
                    ) : (
                        <>
                            {isLoaded ? (
                                <StationsList stations={stations} notFoundMessage="" />
                            ) : (
                                <LoadingScreen
                                    message={intl.formatMessage({
                                        id: 'pages.favoritesPage.LoadingScreen',
                                    })}
                                />
                            )}
                        </>
                    )}
                </>
            )}
        </>
    )
}

export default connect(
    state => ({
        favorites: getFavorites(state),
        stations: getFavoritesStationsList(state),
        hasError: getHasStationsError(state),
    }),
    {
        loadFavoritesStations,
    },
)(FavoritesPage)
