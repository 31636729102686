import axios from 'axios'
import { createSlice } from '@reduxjs/toolkit'

export const STATION_SEARCH_BY_LOCATION_PAGE_REDUCER_KEY = 'stationSearchByLocationPage'

const stationSearchByLocationPageSlice = createSlice({
    name: STATION_SEARCH_BY_LOCATION_PAGE_REDUCER_KEY,
    initialState: {
        stationsLoaded: false,
        stationsLoading: false,
        error: null,
        stations: [],
    },
    reducers: {
        startLoading: (state, action) => {
            state.error = null
            state.stationsLoading = true
            state.stations = []
            state.stationsLoaded = false
        },
        errorLoading: (state, { payload }) => {
            state.stationsLoading = false
            state.stations = []
            state.stationsLoaded = false
            state.error = payload
        },
        setStations: (state, { payload }) => {
            state.error = null
            state.stations = payload
            state.stationsLoaded = true
            state.stationsLoading = false
        },
    },
})

export const getStations = state => state.pages[STATION_SEARCH_BY_LOCATION_PAGE_REDUCER_KEY].stations
export const getStationsLoaded = state => state.pages[STATION_SEARCH_BY_LOCATION_PAGE_REDUCER_KEY].stationsLoaded
export const getStationsLoading = state => state.pages[STATION_SEARCH_BY_LOCATION_PAGE_REDUCER_KEY].stationsLoading
export const getErrors = state => state.pages[STATION_SEARCH_BY_LOCATION_PAGE_REDUCER_KEY].error

export const fetchStationsByLocation = () => async (dispatch, getState) => {
    dispatch(stationSearchByLocationPageSlice.actions.startLoading())

    if (!window.navigator || !window.navigator.geolocation) {
        dispatch(stationSearchByLocationPageSlice.actions.errorLoading('GeolocationError'))
    }

    let position
    // первым шагом получаем геолокацию пользователя
    try {
        position = await getGeoposition()
    } catch (err) {
        // eslint-disable-next-line no-console
        console.error('Ошибка получения геопозиции пользователя', err)
    }

    // если нет геолокации - ошибка
    if (!position) {
        dispatch(stationSearchByLocationPageSlice.actions.errorLoading('GeolocationError'))
        return
    }

    // по геолокации получаем остановки
    try {
        const { data } = await axios.get('/searchStation.php', {
            params: {
                ...position,
            },
        })

        if (!Array.isArray(data)) {
            throw new Error('Неверные данные с сервера')
        }

        data.sort((a, b) => a.dist - b.dist)

        dispatch(stationSearchByLocationPageSlice.actions.setStations(data))
    } catch (err) {
        // eslint-disable-next-line no-console
        console.error('Ошибка получения остановок по геолокации', err)
        dispatch(stationSearchByLocationPageSlice.actions.errorLoading('ServerError'))
    }
}

// async/await (promise) обертка для получения геолокации
function getGeoposition() {
    return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
            (position) => {
                resolve({ lat: position.coords.latitude, lng: position.coords.longitude })
            },
            (err) => {
                reject()
            },
        )
    })
}

export default stationSearchByLocationPageSlice.reducer
