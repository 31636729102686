import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios'

import config from '../resources/config'

export const COMMON_REDUCER_KEY = 'common'

const commonSlice = createSlice({
    name: COMMON_REDUCER_KEY,
    initialState: {
        locale: config[COMMON_REDUCER_KEY].locale,
        themeColor: config[COMMON_REDUCER_KEY].themeColor,
        themeModifier: config[COMMON_REDUCER_KEY].themeModifier,

        transportsIconsVisible: config[COMMON_REDUCER_KEY].transportsIconsVisible,
        transportsNumberVisible: config[COMMON_REDUCER_KEY].transportsNumberVisible,
        tableHeaderVisible: config[COMMON_REDUCER_KEY].tableHeaderVisible,

        error: null,
    },
    reducers: {
        setAvailableRouteTypes: (state, { payload }) => {
            state.availableRouteTypes = payload

            return state
        },
        setLocale: (state, { payload }) => {
            state.locale = payload

            return state
        },
        setTheme: (state, { payload: { themeColor, themeModifier } }) => {
            state.themeColor = themeColor
            state.themeModifier = themeModifier

            return state
        },
        setTransportsIconsVisible: (state, { payload }) => {
            state.transportsIconsVisible = payload

            return state
        },
        setTransportsNumberVisible: (state, { payload }) => {
            state.transportsNumberVisible = payload

            return state
        },
        setTableHeaderVisible: (state, { payload }) => {
            state.tableHeaderVisible = payload

            return state
        },
        setError: (state, { payload }) => {
            state.error = payload
        },
    },
})

export const {
    setAvailableRouteTypes,
    setLocale,
    setTheme,
    setTransportsIconsVisible,
    setTransportsNumberVisible,
    setTableHeaderVisible,
    setError,
} = commonSlice.actions

export const fetchAvailableRouteTypes = () => async (dispatch) => {
    try {
        const { data } = await axios.get('/getAllRouteTypes.php')

        if (!Array.isArray(data)) {
            throw new Error('Неверные данные с сервера')
        }

        dispatch(setAvailableRouteTypes(data))
    } catch (err) {
        // eslint-disable-next-line no-console
        console.error('Ошибка получения типов транспорта', err)
        dispatch(setError('Ошибка получения типов транспорта'))
    }
}

export const getCommon = state => state[COMMON_REDUCER_KEY]
export const getLocale = state => getCommon(state).locale
export const getThemeColor = state => getCommon(state).themeColor
export const getThemeModifier = state => getCommon(state).themeModifier
export const getAvailableRouteTypes = state => getCommon(state).availableRouteTypes
export const getError = state => getCommon(state).error

export const getTransportsIconsVisible = state => getCommon(state).transportsIconsVisible
export const getTransportsNumberVisible = state => getCommon(state).transportsNumberVisible
export const getTableHeaderVisible = state => getCommon(state).tableHeaderVisible

export default commonSlice.reducer
