import get from 'lodash-es/get'
import isArray from 'lodash-es/isArray'
import reduce from 'lodash-es/reduce'

import { history } from '../../services/history'
import { getQueryParams } from '../../services/useGetQuery'
import { LANG_MAP } from '../lang'

import { cleanUnavailableFields } from './01_defaultsConfig'
import { getDeepPathValueMap } from './utils'


// 1. Структура объекта должна совпадать с defaults. Узлы, которых нет в defaults - будут удаляться
// 2. Массивы и их содержимое не мержится а заменяется
// 3. По указаному ключу будут браться значения из query-параметров
// 4.* Для совместимости, добавлено проксирование некоторых параметров старой версии: clr, spk

const queryParamsResolveRules = {
    common: {
        locale: 'lang',
        themeColor: 'themeColor',
        themeModifier: 'themeModifier',

        transportsIconsVisible: 'iconsVisible',
        transportsNumberVisible: 'numberVisible',
        tableHeaderVisible: 'headerVisible',
    },
    pages: {
        stationForecastPage: {
            // Т.к. это массив, в query параметр нужно передавать JSON массив
            // н.р.: &exceptTransportType=["М"]
            exceptTransportType: 'sfpExceptTransportType',
            goalZoneIds: 'sfpGoalZoneIds',
            transportColumnDescView: 'sfpTransportColumnDescView',
        },
    },
}

const resolveRulesMapDirty = getDeepPathValueMap(queryParamsResolveRules)
const resolveRulesMap = cleanUnavailableFields(resolveRulesMapDirty)

export const queryConfigMap = getQueryConfigMap()

function getQueryConfigMap() {
    const queryParams = getQueryParams()

    // Clean and proxy legacy query params

    let fixQueryParams = false
    if (queryParams.lang && !LANG_MAP[queryParams.lang]) {
        fixQueryParams = true
        delete queryParams.lang
    }

    // Legacy param: clr

    if (queryParams.clr) {
        fixQueryParams = true

        if (queryParams.clr === 'night') {
            queryParams.themeColor = 'dark'
            queryParams.themeModifier = 'normal'
        }

        delete queryParams.clr
    }

    // Legacy param: spk

    if (queryParams.spk) {
        fixQueryParams = true
        delete queryParams.spk

        history.replace(`/station_forecasts/speaker${history.location.search}`)
    }

    if (fixQueryParams) {
        const newSearch = new URLSearchParams(queryParams)
        history.replace(`${history.location.pathname}?${newSearch.toString()}`)
    }

    return reduce(resolveRulesMap, (queryConfigMap, queryFieldName, path) => {
        if (queryParams[queryFieldName] !== undefined) {
            queryConfigMap[path] = queryParams[queryFieldName]
        }

        return queryConfigMap
    }, {})
}

/**
 * Нам необходимо проверять, все ли данные в сторе соответствуют query параметрам в сответствии с queryParamsResolveRules
 * @param storeState стэйт стора
 */
export function updateQuery(storeState) {
    const newSearch = getUpdatedQueryParams(storeState)

    if (!newSearch) {
        return
    }

    const path = `${history.location.pathname}?${newSearch}`

    history.replace(path)
}

export function getUpdatedQueryParams(storeState, search) {
    let needToUpdateQueryParams = false
    const currentQueryParams = getQueryParams(search)

    const newQueryParams = reduce(resolveRulesMap, (newQueryParams, queryFieldName, path) => {
        let value = get(storeState, path)

        if (isArray(value)) {
            value = JSON.stringify(value)
        } else {
            value = value.toString()
        }

        if (currentQueryParams[queryFieldName] !== value) {
            newQueryParams.set(queryFieldName, value)
            needToUpdateQueryParams = true
        }

        return newQueryParams
    }, new URLSearchParams(currentQueryParams))

    if (!needToUpdateQueryParams) {
        return null
    }

    return newQueryParams.toString()
}
