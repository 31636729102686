import React from 'react'


export default () => (
    <>
        <path
            d="M24.3032 21.4173L24.3032 21.4173C24.5263 21.1942 24.888 21.1943 25.1112 21.4174C26.3099 22.6162 26.3099 24.567 25.1112 25.7657L25.1111 25.7658C24.9996 25.8774 24.8535 25.9331 24.7072 25.9331C24.5608 25.9331 24.4148 25.8774 24.3032 25.7658L24.3032 25.7658C24.0801 25.5428 24.0801 25.181 24.3032 24.9579L24.3032 24.9579C25.0566 24.2045 25.0566 22.9787 24.3032 22.2253L24.3032 22.2252C24.0801 22.0022 24.0801 21.6405 24.3032 21.4173Z"
            fill="#333333"
            stroke="#333333"
            strokeWidth="0.2"
        />
        <path
            d="M26.271 19.4497C26.271 19.4497 26.271 19.4497 26.271 19.4497C26.494 19.2267 26.8558 19.2266 27.0789 19.4497C29.3627 21.7335 29.3627 25.4498 27.0789 27.7335C26.9674 27.845 26.8213 27.9008 26.675 27.9008C26.5286 27.9008 26.3825 27.845 26.271 27.7335C26.0479 27.5104 26.0479 27.1487 26.271 26.9255C28.1094 25.0872 28.1094 22.0961 26.271 20.2577C26.0479 20.0346 26.0479 19.6729 26.271 19.4497Z"
            fill="#333333"
            stroke="#333333"
            strokeWidth="0.2"
        />
        <path
            d="M28.4668 17.2537C28.4668 17.2537 28.4668 17.2537 28.4668 17.2537C28.6899 17.0306 29.0517 17.0308 29.2747 17.2537C30.9678 18.9469 31.9001 21.1973 31.9 23.5915C31.9 25.9859 30.9678 28.2364 29.2747 29.9295C29.1632 30.041 29.0171 30.0968 28.8708 30.0968C28.7244 30.0968 28.5783 30.041 28.4668 29.9295C28.2437 29.7064 28.2437 29.3447 28.4668 29.1215C29.9438 27.6446 30.7574 25.6804 30.7574 23.5916C30.7574 21.5029 29.9438 19.5387 28.4668 18.0617C28.2437 17.8386 28.2437 17.4769 28.4668 17.2537Z"
            fill="#333333"
            stroke="#333333"
            strokeWidth="0.2"
        />
        <path
            d="M22.4204 16.8665L22.4205 16.8666C22.6233 16.9596 22.7534 17.1625 22.7534 17.3857V29.7977C22.7534 30.0209 22.6234 30.2237 22.4204 30.3169L22.4204 30.3169C22.3441 30.3519 22.2629 30.3691 22.1822 30.3691C22.048 30.3691 21.9154 30.3218 21.8096 30.231L17.5438 26.5652L17.5157 26.5411H17.4787H15.5285C15.213 26.5411 14.9572 26.2853 14.9572 25.9697V21.2137C14.9572 20.8981 15.213 20.6424 15.5285 20.6424H17.4787H17.5157L17.5438 20.6182L21.8096 16.9524L21.8097 16.9524C21.9789 16.8068 22.2175 16.7732 22.4204 16.8665ZM16.1998 21.785H16.0998V21.885V25.2984V25.3984H16.1998H17.0562H17.1562V25.2984V21.885V21.785H17.0562H16.1998ZM21.4456 28.4114L21.6108 28.5534V28.3356V18.8477V18.63L21.4456 18.7719L18.3337 21.4461L18.2988 21.476V21.522V25.6614V25.7073L18.3337 25.7372L21.4456 28.4114Z"
            fill="#333333"
            stroke="#333333"
            strokeWidth="0.2"
        />
        <line
            x1="13.6464"
            y1="32.6464"
            x2="31.6464"
            y2="14.6464"
            stroke="white"
        />
        <line
            x1="14.3268"
            y1="30.7554"
            x2="29.3268"
            y2="15.7554"
            stroke="#333333"
            strokeWidth="1.5"
        />
    </>
)
