import isPlainObject from 'lodash-es/isPlainObject'
import fromPairs from 'lodash-es/fromPairs'


/**
 * Трансформируем объект с вложенностями в плоский Map, где ключ - полный путь к значению.
 * Вложеный путь простраивается только для plain объектов,
 * т.е. массивы рассматриваются как значения и их индексы в путь не включаются
 * { a: { b: 2 }, c: 3 } => { 'a.b': 2, 'c': 3 }
 * @param object
 * @returns {{}}
 */
export const getDeepPathValueMap = (object) => {
    const pairs = getPathValueMap(object)

    return fromPairs(pairs)

    function getPathValueMap(value, path = '') {
        if (isPlainObject(value)) {
            const outPairs = []

            Object
                .keys(value)
                .forEach((key) => {
                    const newPairs = getPathValueMap(value[key], `${path}${path ? '.' : ''}${key}`)
                    outPairs.push(...newPairs)
                })

            return outPairs
        }

        return [[path, value]]
    }
}
