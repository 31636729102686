import React, { useRef, useEffect } from 'react'
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock'
import { Link } from 'react-router-dom'
import { useIntl } from 'react-intl'

import {
    IconDrawerClose,
    IconArrowForward,
    IconSearchByType,
    IconSearchByKeyword,
    IconSearchByLocation,
    IconMenuFavorites,
    IconHistory,
    IconFeedback,
    IconDesktop,
} from '../../resources/icons'
import config from '../../resources/config'

import IconMarker from './IconMarker'
import './Drawer.scss'

/* eslint prefer-destructuring: ["off"] */
const feedbackConfig = config.pages.feedbackPage
const desktopVersionURL = config.common.desktopVersionURL

/* eslint react/prop-types: ["off"] */
const Drawer = ({ closeDrawer }) => {
    const intl = useIntl()
    const drawerRef = useRef()

    useEffect(() => {
        disableBodyScroll(drawerRef.current)
        return () => {
            clearAllBodyScrollLocks()
        }
    }, [])

    const feedbackSubject = intl.formatMessage(
        { id: 'pages.feedbackPage.subject' },
        { hostname: window.location.hostname },
    )

    return (
        <div ref={drawerRef} className="drawer-wrapper">
            <div className="icon-marker-wrapper">
                <IconMarker />
            </div>
            <div className="head">
                <div className="close-button" onClick={closeDrawer} aria-hidden>
                    <IconDrawerClose />
                </div>
            </div>
            <div className="menu-wrapper">
                <Link to="/station_search_by_type" className="menu-item" onClick={closeDrawer}>
                    <div className="menu-iIcon-wrapper">
                        <IconSearchByType />
                    </div>
                    <div className="menu-item-title">
                        {intl.formatMessage({
                            id: 'widgets.drawer.menu.searchByType',
                        })}
                    </div>
                    <IconArrowForward />
                </Link>
                <Link to="/station_search_by_keyword" className="menu-item" onClick={closeDrawer}>
                    <div className="menu-iIcon-wrapper">
                        <IconSearchByKeyword />
                    </div>
                    <div className="menu-item-title">
                        {intl.formatMessage({
                            id: 'widgets.drawer.menu.searchByKeyword',
                        })}
                    </div>
                    <IconArrowForward />
                </Link>
                <Link to="/station_search_by_location" className="menu-item" onClick={closeDrawer}>
                    <div className="menu-iIcon-wrapper">
                        <IconSearchByLocation />
                    </div>
                    <div className="menu-item-title">
                        {intl.formatMessage({
                            id: 'widgets.drawer.menu.searchByLocation',
                        })}
                    </div>
                    <IconArrowForward />
                </Link>
                <Link to="/favorites" className="menu-item" onClick={closeDrawer}>
                    <div className="menu-iIcon-wrapper">
                        <IconMenuFavorites />
                    </div>
                    <div className="menu-item-title">
                        {intl.formatMessage({
                            id: 'widgets.drawer.menu.favorites',
                        })}
                    </div>
                    <IconArrowForward />
                </Link>
                <Link to="/history" className="menu-item" onClick={closeDrawer}>
                    <div className="menu-iIcon-wrapper">
                        <IconHistory />
                    </div>
                    <div className="menu-item-title">
                        {intl.formatMessage({
                            id: 'widgets.drawer.menu.history',
                        })}
                    </div>
                    <IconArrowForward />
                </Link>
                <a
                    href={`mailto:${feedbackConfig.feedbackEmail}?subject=${feedbackSubject}`}
                    className="menu-item"
                    onClick={closeDrawer}
                >
                    <div className="menu-iIcon-wrapper">
                        <IconFeedback />
                    </div>
                    <div className="menu-item-title">
                        {intl.formatMessage({
                            id: 'widgets.drawer.menu.feedback',
                        })}
                    </div>
                </a>
                <div className="menu-filler" />
                <a href={desktopVersionURL} className="menu-item menu-bottom-item" onClick={closeDrawer}>
                    <div className="menu-iIcon-wrapper">
                        <IconDesktop />
                    </div>
                    <div className="menu-item-title">
                        {intl.formatMessage({
                            id: 'widgets.drawer.menu.desktopVersion',
                        })}
                    </div>
                </a>
            </div>
        </div>
    )
}

export default Drawer
