import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { useIntl } from 'react-intl'

import StationSearchPageTitle from '../../components/StationSearch/PageTitle'
import StationsList from '../../components/StationSearch/StationsList'
import LoadingScreen from '../../components/StationSearch/LoadingScreen'
import ErrorScreen from '../../components/StationSearch/ErrorScreen'
import {
    getHistory,
    getHistoryStationsList,
    loadHistoryStations,
    getHasStationsError,
} from '../../store/historyPageSlice'

import EmptyScreen from './EmptyScreen'

/* eslint react/prop-types: ["off"] */
const HistoryPage = ({ history, stations, hasError, loadHistoryStations }) => {
    const intl = useIntl()

    useEffect(() => {
        loadHistoryStations()
    }, [history, loadHistoryStations])

    const hasItems = Array.isArray(history) && history.length > 0
    const isLoaded = stations.length === history.length

    return (
        <>
            <StationSearchPageTitle
                title={intl.formatMessage({
                    id: 'pages.historyPage.title',
                })}
                headTitle={intl.formatMessage({
                    id: 'pages.historyPage.htmlHead.title',
                })}
            />
            {!hasItems ? (
                <EmptyScreen />
            ) : (
                <>
                    {hasError ? (
                        <ErrorScreen
                            message={intl.formatMessage({
                                id: 'pages.historyPage.ErrorScreen',
                            })}
                        />
                    ) : (
                        <>
                            {isLoaded ? (
                                <StationsList stations={stations} notFoundMessage="" />
                            ) : (
                                <LoadingScreen
                                    message={intl.formatMessage({
                                        id: 'pages.historyPage.LoadingScreen',
                                    })}
                                />
                            )}
                        </>
                    )}
                </>
            )}
        </>
    )
}

export default connect(
    state => ({
        history: getHistory(state),
        stations: getHistoryStationsList(state),
        hasError: getHasStationsError(state),
    }),
    {
        loadHistoryStations,
    },
)(HistoryPage)
