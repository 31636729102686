import React from 'react'
import PropTypes from 'prop-types'


const propTypes = {
    color: PropTypes.string,
}

const Svg = ({ color }) => (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            className="fill"
            style={{ fill: color }}
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18 0H2C0.89543 0 0 0.89543 0 2V18C0 19.1046 0.89543 20 2 20H18C19.1046 20 20 19.1046 20 18V2C20 0.89543 19.1046 0 18 0ZM2 18V2H18V18H2ZM12.7999 13H7.19992L6 16H4L9 4H11L16 16H14L12.7999 13ZM9.99974 6L11.9999 11H7.99987L9.99974 6Z"
        />
    </svg>
)

Svg.propTypes = propTypes

export default Svg
