export const arrayDiff = (arr1, arr2) => arr1.filter(item => arr2.indexOf(item) === -1)

export const intersects = (arr1, arr2) => {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < arr1.length; i++) {
        if (arr2.indexOf(arr1[i]) !== -1) {
            return true
        }
    }

    return false
}

export const allContains = (what, where) => {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < what.length; i++) {
        if (where.indexOf(what[i]) === -1) {
            return false
        }
    }

    return true
}
