import React from 'react'


const propTypes = {}

const Svg = () => (
    <svg
        width="60"
        height="60"
        viewBox="0 0 60 60"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M59.4273 50.2049L33.658 5.57124C32.8945 4.24878 31.5271 3.45929 30 3.45929C28.4729 3.45929 27.1055 4.24878 26.342 5.57124L0.572695 50.2049C-0.190898 51.5274 -0.190898 53.1065 0.572695 54.4288C1.33617 55.7513 2.70363 56.5408 4.2307 56.5408H55.7693C57.2964 56.5408 58.6638 55.7513 59.4273 54.4288C60.1909 53.1065 60.1909 51.5274 59.4273 50.2049ZM56.379 52.6689C56.3032 52.8004 56.1228 53.0209 55.7694 53.0209H4.2307C3.87703 53.0209 3.6968 52.8005 3.62109 52.6689C3.54527 52.5375 3.44426 52.2712 3.62109 51.965L29.3903 7.33116C29.5671 7.02495 29.8481 6.97913 29.9999 6.97913C30.1518 6.97913 30.4328 7.02484 30.6095 7.33116L56.3789 51.965C56.5559 52.2712 56.4548 52.5375 56.379 52.6689Z"
            fill="#9D3ADC"
        />
        <path
            d="M31.7607 19.4643H28.2407V38.2374H31.7607V19.4643Z"
            fill="#45C1FF"
        />
        <path
            d="M30.0005 46.4507C31.2965 46.4507 32.3472 45.4 32.3472 44.104C32.3472 42.808 31.2965 41.7573 30.0005 41.7573C28.7045 41.7573 27.6538 42.808 27.6538 44.104C27.6538 45.4 28.7045 46.4507 30.0005 46.4507Z"
            fill="#45C1FF"
        />
    </svg>
)

Svg.propTypes = propTypes

export default Svg
