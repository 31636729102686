import React from 'react'
import PropTypes from 'prop-types'

const propTypes = {
    color: PropTypes.string,
}

const Svg = ({ color }) => (
    <svg width="20" height="20" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M27.9258 10.7738C27.7139 10.1212 27.0822 9.67283 26.1931 9.54359L18.9463 8.49043L15.7054 1.92406C15.3079 1.11827 14.6862 0.656204 14.0001 0.656204C13.3139 0.656204 12.6923 1.11827 12.2945 1.92406L9.05402 8.49043L1.80683 9.54359C0.917729 9.67283 0.286043 10.1212 0.0741279 10.7738C-0.137787 11.4265 0.109589 12.1605 0.753025 12.7875L5.99706 17.8988L4.7589 25.1165C4.60061 26.0396 4.87041 26.5929 5.12462 26.8943C5.42306 27.2481 5.85778 27.4429 6.34912 27.4429C6.71911 27.4429 7.11239 27.3348 7.51828 27.1214L14.0001 23.7137L20.482 27.1214C20.8879 27.3348 21.2812 27.4429 21.651 27.4431H21.6512C22.1425 27.4431 22.5775 27.2481 22.8757 26.8943C23.1299 26.5929 23.3997 26.0396 23.2412 25.1165L22.0035 17.8988L27.2471 12.7877C27.8905 12.1605 28.1379 11.4265 27.9258 10.7738ZM26.1021 11.6127L20.5496 17.0251C20.3562 17.2135 20.268 17.4853 20.3137 17.7512L21.6243 25.394C21.6625 25.6164 21.6461 25.7427 21.6309 25.8016C21.5724 25.7978 21.4468 25.7751 21.2455 25.6694L14.3818 22.0609C14.2622 21.9981 14.1312 21.9667 14.0001 21.9667C13.8689 21.9667 13.7377 21.9981 13.6183 22.0609L6.755 25.6692C6.55377 25.7749 6.42794 25.7978 6.36941 25.8016C6.35446 25.7427 6.33779 25.6164 6.37603 25.394L7.68704 17.7514C7.73254 17.4853 7.64432 17.2135 7.45099 17.0253L1.89826 11.6127C1.73676 11.4553 1.67588 11.3436 1.65324 11.287C1.70472 11.2545 1.81965 11.1998 2.04289 11.1673L9.7169 10.0522C9.98414 10.0133 10.2151 9.84544 10.3345 9.6034L13.7659 2.65017C13.8659 2.44787 13.9533 2.35558 14.0003 2.31649C14.0471 2.35558 14.1346 2.44787 14.2344 2.65017L17.6661 9.6034C17.7857 9.84544 18.0166 10.0133 18.2839 10.0522L25.9572 11.1673C26.1807 11.1998 26.2954 11.2545 26.3469 11.287C26.3242 11.3434 26.2636 11.4553 26.1021 11.6127Z"
            style={{ fill: color }}
            stroke="white"
            className="fill"
        />
    </svg>
)

Svg.propTypes = propTypes

export default Svg
