import config from '../resources/config'

import { pageOpened, pageClosed, fetchStationInfo, fetchForecasts, forecastsFetchFail, setForecasts } from './stationForecastPageSlice'


export const stationForecastPageMiddleware = (store) => {
    let forecastsFetchTimeout = null

    return next => (action) => {
        next(action)

        // Добывем первоначальные данные
        if (action.type === pageOpened.toString()) {
            store.dispatch(fetchStationInfo())

            store.dispatch(fetchForecasts())
        }

        // Зацикливаем пулинг транспорта
        if (
            [setForecasts.toString(), forecastsFetchFail.toString()].includes(action.type)
            && forecastsFetchTimeout === null
        ) {
            forecastsFetchTimeout = setTimeout(() => {
                forecastsFetchTimeout = null

                store.dispatch(fetchForecasts())
            }, config.common.backend.forecastsPoolTime)
        }

        // Прерываем опрос при хакрытии страницы (еще есть защита в самом фетче через stationId === null)
        if (action.type === pageClosed.toString()) {
            forecastsFetchTimeout = null
        }
    }
}
