import { LANG_MAP } from '../lang'
import { TRANSPORT_COLUMN_DESC_VIEW } from '../../pages/StationForecastPage/Grid/constants'

import { THEME_COLOR, THEME_MODIFIER } from './theme-constants'
import { getDeepPathValueMap } from './utils'

// 1. Структура данных должна совпадать со структурой стейта стора
// 2. Массивы и их содержимое не мержится а заменяется

const defaultsConfig = {
    common: {
        locale: LANG_MAP.ru.locale,
        themeColor: THEME_COLOR.light,
        themeModifier: THEME_MODIFIER.normal,
        desktopVersionURL: '/',

        backend: {
            url: '/',
            cityCode: 'tula',
            forecastsPoolTime: 10 * 1000,
        },

        transportsIconsVisible: true,
        transportsNumberVisible: true,
        tableHeaderVisible: true,
    },
    pages: {
        stationForecastPage: {
            exceptTransportType: [],
            goalZoneIds: [],
            transportColumnDescView: TRANSPORT_COLUMN_DESC_VIEW.whenNextBus,
        },
        historyPage: {
            stationHistoryLength: 10,
        },
        feedbackPage: {
            feedbackEmail: 'feedback@bus62.ru',
        },
    },
}

export const defaultsConfigMap = getDeepPathValueMap(defaultsConfig)
const availablePaths = Object.keys(defaultsConfigMap)

/**
 * Создает новый map, в который копируются только значения доступные в defaults
 * @param mapToCheck - map'а, значения которой чистим
 * @returns {{}} новый map
 */
export const cleanUnavailableFields = mapToCheck => (
    availablePaths.reduce((cleanedMap, path) => {
        if (mapToCheck[path] !== undefined) {
            cleanedMap[path] = mapToCheck[path]
        }

        return cleanedMap
    }, {})
)
