export function localStorageGetItem(name) {
    let result

    const value = window.localStorage.getItem(name)

    if (value) {
        result = value
        if (typeof value === 'string') {
            try {
                result = JSON.parse(value)
            } catch (err) {
                // eslint-disable-next-line no-console
                console.error('Ошибка сохранения данных в LocalStorage', err)
            }
        }
    }

    return result
}

export function localStorageSetItem(name, value) {
    if (typeof value === 'object') {
        value = JSON.stringify(value)
    }
    window.localStorage.setItem(name, value)
}
