import axios from 'axios'
import { createSlice } from '@reduxjs/toolkit'

export const STATION_SEARCH_BY_KEYWORD_PAGE_REDUCER_KEY = 'stationSearchByKeywordPage'

const stationSearchByKeywordPageSlice = createSlice({
    name: STATION_SEARCH_BY_KEYWORD_PAGE_REDUCER_KEY,
    initialState: {
        stationsLoaded: false,
        stationsLoading: false,
        hasError: false,
        stations: [],
    },
    reducers: {
        setStations: (state, { payload }) => {
            state.hasError = false
            state.stations = payload
            state.stationsLoaded = true
            state.stationsLoading = false
        },
        startLoading: (state, action) => {
            state.hasError = false
            state.stationsLoading = true
            state.stations = []
            state.stationsLoaded = false
        },
        errorLoading: (state, action) => {
            state.hasError = true
            state.stationsLoading = false
            state.stations = []
            state.stationsLoaded = false
        },
        clearStations: (state, action) => {
            state.hasError = false
            state.stations = []
            state.stationsLoaded = false
        },
    },
})

export const { clearStations } = stationSearchByKeywordPageSlice.actions

export const getStations = state => state.pages[STATION_SEARCH_BY_KEYWORD_PAGE_REDUCER_KEY].stations
export const getStationsLoaded = state => state.pages[STATION_SEARCH_BY_KEYWORD_PAGE_REDUCER_KEY].stationsLoaded
export const getStationsLoading = state => state.pages[STATION_SEARCH_BY_KEYWORD_PAGE_REDUCER_KEY].stationsLoading
export const getHasError = state => state.pages[STATION_SEARCH_BY_KEYWORD_PAGE_REDUCER_KEY].hasError

export const fetchStationsByKeyworld = query => async (dispatch, getState) => {
    dispatch(stationSearchByKeywordPageSlice.actions.startLoading())

    try {
        const { data } = await axios.get('/searchStation.php', {
            params: {
                q: query,
            },
        })

        if (!Array.isArray(data)) {
            throw new Error('Неверные данные с сервера')
        }

        dispatch(stationSearchByKeywordPageSlice.actions.setStations(data))
    } catch (err) {
        // eslint-disable-next-line no-console
        console.error('Ошибка получения остановок по ключевому слову', err)
        dispatch(stationSearchByKeywordPageSlice.actions.errorLoading())
    }
}

export default stationSearchByKeywordPageSlice.reducer
