import React from 'react'
import PropTypes from 'prop-types'


const propTypes = {
    color: PropTypes.string,
}

const Svg = ({ color }) => (
    <svg
        width="8"
        height="10"
        viewBox="0 0 8 5"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            className="fill"
            style={{ fill: color }}
            d="M4.65465 4.43305C4.27888 4.75848 3.72112 4.75848 3.34535 4.43305L0.831418 2.25593C0.131514 1.64979 0.560188 0.5 1.48607 0.5L6.51393 0.499999C7.43981 0.499999 7.86848 1.64979 7.16858 2.25593L4.65465 4.43305Z"
        />
    </svg>
)

Svg.propTypes = propTypes

export default Svg
