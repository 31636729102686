import React from 'react'


const propTypes = {}

const Svg = () => (
    <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle
            className="icon-dot"
            cx="7"
            cy="7"
            r="3"
        />
    </svg>
)

Svg.propTypes = propTypes

export default Svg
