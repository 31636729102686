import get from 'lodash-es/get'

// В ключе кеширования localStorage используем версию, чтоб в случае изменения структуры или типов
// не заморачиваться с миграцией, а просто затереть старые данные, выпустив новую версию
import { version } from '../../../package.json'

import { cleanUnavailableFields } from './01_defaultsConfig'
import { getDeepPathValueMap } from './utils'


const LOCAL_STORAGE_KEY_BASE = 'user_config-'
const LOCAL_STORAGE_KEY = `${LOCAL_STORAGE_KEY_BASE}${version}`

// 1. Структура объекта должна совпадать с defaults. Узлы, которых нет в defaults - будут удаляться
// 2. Массивы и их содержимое не мержится а заменяется
// 3. Значения в этом конфиге роли не играют - главное структура
// 4. Только указанная ниже структура будет сохраняться в localStorage и читаться из него
const userConfigTemplate = {
    common: {
        locale: '',
        themeColor: '',
        themeModifier: '',

        transportsIconsVisible: '',
        transportsNumberVisible: '',
        tableHeaderVisible: '',
    },
    pages: {
        stationForecastPage: {
            exceptTransportType: '',
            goalZoneIds: '',
            transportColumnDescView: '',
        },
    },
}

const userConfigTemplateMapDirty = getDeepPathValueMap(userConfigTemplate)
const userConfigTemplateMap = cleanUnavailableFields(userConfigTemplateMapDirty)
const userConfigTemplatePaths = Object.keys(userConfigTemplateMap)

// Get initial data from localStorage

const userConfigMap = getFromLocalStorage()

function getFromLocalStorage() {
    let userConfigMap = {}

    try {
        const value = localStorage[LOCAL_STORAGE_KEY]

        const userConfigMapDirty = value ? JSON.parse(value) : {}

        userConfigMap = cleanUnavailableFields(userConfigMapDirty)
    } catch (e) {
        if (process.env.NODE_ENV !== 'production') {
            // eslint-disable-next-line no-console
            console.warn(`Ошибка парсинга данных из localStorage[${LOCAL_STORAGE_KEY}]`, e)
        }
    }

    return userConfigMap
}

let previousSavedValue = null
function setToLocalStorage(storeState) {
    const config = userConfigTemplatePaths.reduce((outConfig, path) => {
        const value = get(storeState, path)

        if (value !== undefined) {
            outConfig[path] = value
        }

        return outConfig
    }, {})

    const configToSave = JSON.stringify(config)

    if (configToSave === previousSavedValue) {
        // Необдходимо сравнивать с последним сохраненным конфигом, а не значением в localStorage
        // чтоб не получить конкурирующую запись, когда открыты несколько вкладок и кажый пытается переписать параметры другого
        // а так, получается, кто что-то поменял - тот и запишет значение, т.е. писать в localStorage будет активная вкладка
        return
    }

    try {
        previousSavedValue = configToSave
        localStorage[LOCAL_STORAGE_KEY] = configToSave
    } catch (e) {
        // eslint-disable-next-line no-console
        console.warn(`Ошибка записи данных в localStorage[${LOCAL_STORAGE_KEY}]`, e)
    }
}

export { userConfigMap, setToLocalStorage }


// Clean data of old app versions

Object.keys(localStorage).forEach((key) => {
    if (key.startsWith(LOCAL_STORAGE_KEY_BASE) && key !== LOCAL_STORAGE_KEY) {
        delete localStorage[key]
    }
})
