import React, { useCallback, useEffect } from 'react'
import { connect } from 'react-redux'
import { push as navigateTo } from 'connected-react-router'
import map from 'lodash-es/map'
import { useIntl } from 'react-intl'
import { useLocation } from 'react-router-dom'

import { getAvailableRouteTypes, fetchAvailableRouteTypes } from '../../../store/commonSlice'
import {
    getStationInfo,
    getExceptTransportType,
    setExceptTransportType,
    getGoalZoneIds,
    setGoalZoneIds,
    addToExceptedRoutes,
    removeFromExceptedRoutes,
} from '../../../store/stationForecastPageSlice'
import CheckBox from '../../../components/Checkbox'
import { PrimaryButton, SecondaryButton } from '../../../components/Button'

import './Filter.scss'
import RoutesFilter from './RoutesFilter'

/* eslint react/prop-types: ["off"] */
const StationForecastsFilter = ({
    availableRouteTypes,
    stationInfo,
    setExceptTransportType,
    goalZoneIds,
    setGoalZoneIds,
    navigateTo,
    fetchAvailableRouteTypes,
    stationId,
    addToExceptedRoutes,
    removeFromExceptedRoutes,
}) => {
    const intl = useIntl()
    const location = useLocation()

    // except route
    const addRouteException = useCallback(
        (routeIds, shortname) => {
            addToExceptedRoutes(stationId, routeIds, shortname)
        },
        [stationId, addToExceptedRoutes],
    )
    const removeRouteException = useCallback(
        (routeIds, shortname) => {
            removeFromExceptedRoutes(stationId, routeIds, shortname)
        },
        [stationId, removeFromExceptedRoutes],
    )

    // Zones

    const toggleZoneId = useCallback(
        (e) => {
            const { zoneId } = e.currentTarget.dataset
            const zoneIdsFinal = [...goalZoneIds]
            const position = zoneIdsFinal.indexOf(zoneId)

            if (position === -1) {
                zoneIdsFinal.push(zoneId)
            } else {
                zoneIdsFinal.splice(position, 1)
            }

            setGoalZoneIds(zoneIdsFinal)
        },
        [goalZoneIds, setGoalZoneIds],
    )

    // Button handlers

    const submitHandler = useCallback(() => {
        navigateTo(`/station_forecasts${location.search}`)
    }, [navigateTo, location])

    const resetHandler = useCallback(() => {
        setExceptTransportType([])
        setGoalZoneIds([])
        navigateTo(`/station_forecasts${location.search}`)
    }, [setExceptTransportType, setGoalZoneIds, navigateTo, location])

    useEffect(() => {
        if (!availableRouteTypes) {
            fetchAvailableRouteTypes()
        }
    }, [availableRouteTypes, fetchAvailableRouteTypes])

    if (!availableRouteTypes) {
        return null
    }

    return (
        <>
            <div className="sfp-filter-group">
                <div className="title">{intl.formatMessage({ id: 'pages.stationForecasts.filter.transportType' })}</div>

                {availableRouteTypes.map(routeType => (
                    <RoutesFilter
                        stationId={stationId}
                        key={routeType.id}
                        routeType={routeType}
                        addRouteException={addRouteException}
                        removeRouteException={removeRouteException}
                    />
                ))}
            </div>

            {Object.keys(stationInfo.zones).length > 0 && (
                <div className="sfp-filter-group">
                    <div className="title">{intl.formatMessage({ id: 'pages.stationForecasts.filter.stations' })}</div>

                    {map(stationInfo.zones, zone => (
                        <div
                            className="line"
                            key={zone.zone_id}
                            data-zone-id={zone.zone_id}
                            onClick={toggleZoneId}
                            aria-hidden
                        >
                            <CheckBox checked={goalZoneIds.includes(zone.zone_id)} />
                            <div>{zone.zone_name}</div>
                        </div>
                    ))}
                </div>
            )}

            <PrimaryButton onClick={submitHandler}>
                {intl.formatMessage({ id: 'pages.stationForecasts.filter.goToGrid' })}
            </PrimaryButton>
            <SecondaryButton onClick={resetHandler}>
                {intl.formatMessage({ id: 'pages.stationForecasts.filter.reset' })}
            </SecondaryButton>
        </>
    )
}

export default connect(
    state => ({
        availableRouteTypes: getAvailableRouteTypes(state),
        stationInfo: getStationInfo(state),
        exceptTransportType: getExceptTransportType(state),
        goalZoneIds: getGoalZoneIds(state),
    }),
    {
        setExceptTransportType,
        setGoalZoneIds,
        navigateTo,
        fetchAvailableRouteTypes,
        addToExceptedRoutes,
        removeFromExceptedRoutes,
    },
)(StationForecastsFilter)
