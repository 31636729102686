import React from 'react'
import PropTypes from 'prop-types'


const propTypes = {
    color: PropTypes.string,
}

const Svg = ({ color }) => (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect
            className="fill-stroke"
            style={{ stroke: color }}
            x="0.6"
            y="0.6"
            width="18.8"
            height="18.8"
            rx="1.4"
            strokeWidth="1.2"
            fill="none"
        />
        <path
            className="fill"
            style={{ fill: color }}
            d="M12.3327 14.9464C11.6394 15.6398 10.7177 16.0215 9.73714 16.0215C7.71355 16.0215 6.06717 14.3752 6.06717 12.3514C6.06717 11.3708 6.44888 10.4489 7.14204 9.75563C7.33309 9.56458 7.33302 9.25482 7.14197 9.06377C6.95092 8.87266 6.64116 8.87273 6.45012 9.06384C5.57223 9.94185 5.08878 11.1093 5.08878 12.3513C5.08878 14.9145 7.174 16.9999 9.73714 16.9999C10.979 16.9999 12.1465 16.5163 13.0245 15.6381C13.2156 15.447 13.2155 15.1373 13.0245 14.9463C12.8334 14.7554 12.5237 14.7554 12.3327 14.9464Z"
        />
        <path
            className="fill"
            style={{ fill: color }}
            d="M16.422 13.8736H15.0304L14.1688 11.4831C14.0989 11.2891 13.9148 11.1598 13.7086 11.1598H10.0812V8.96325H13.45C13.7202 8.96325 13.9392 8.74429 13.9392 8.47405C13.9392 8.20382 13.7202 7.98486 13.45 7.98486H10.0812V6.47519C10.0812 6.20496 9.86218 5.98599 9.59201 5.98599C9.32184 5.98599 9.10281 6.20496 9.10281 6.47519V11.6489C9.10281 11.9192 9.32184 12.1381 9.59201 12.1381H13.3648L14.2264 14.5286C14.2963 14.7227 14.4804 14.852 14.6866 14.852H16.4219C16.6921 14.852 16.9111 14.633 16.9111 14.3628C16.9111 14.0925 16.6922 13.8736 16.422 13.8736Z"
        />
        <path
            className="fill"
            style={{ fill: color }}
            d="M9.61045 5.37672C10.2668 5.37672 10.7989 4.84486 10.7989 4.18803C10.7989 3.53231 10.2668 3 9.61045 3C8.95408 3 8.42209 3.53231 8.42209 4.1881C8.42209 4.84486 8.95408 5.37672 9.61045 5.37672Z"
        />
    </svg>
)

Svg.propTypes = propTypes

export default Svg
