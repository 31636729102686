import React from 'react'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'

import './PageTitle.scss'


const propTypes = {
    title: PropTypes.string,
    headTitle: PropTypes.string,
}

const StationSearchPageTitle = ({ title, headTitle }) => (
    <>
        <Helmet>
            <meta name="description" content={headTitle} />
            <title>{headTitle}</title>
        </Helmet>
        <div className="ss-title-wrapper">
            <div className="title">{title}</div>
        </div>
    </>
)

StationSearchPageTitle.propTypes = propTypes

export default StationSearchPageTitle
