export const testStationInfo = {
    name: 'Полетаевский рынок',
    lng: '39715523',
    lat: '54609926',
    description: 'в рощу',
    routeTypes: [
        { id: '3', short_name: 'М' },
        { id: '1', short_name: 'А' },
        { id: '2', short_name: 'Т' },
    ],
    zones: {
        7: { zone_id: 7, zone_name: 'Пл. Ленина', zonecategory_id: 2, route_ids: [60] },
        8: { zone_id: 8, zone_name: 'Пл. Театральная', zonecategory_id: 2, route_ids: [60] },
        12: { zone_id: 12, zone_name: 'Пл. Победы', zonecategory_id: 2, route_ids: [60, 135] },
        13: { zone_id: 13, zone_name: 'Центр', zonecategory_id: 1, route_ids: [60, 135] },
        14: { zone_id: 14, zone_name: 'Роща', zonecategory_id: 1, route_ids: [135] },
        15: { zone_id: 15, zone_name: 'Дашки-Песочни', zonecategory_id: 1, route_ids: [76] },
        16: { zone_id: 16, zone_name: 'Дашки-Военные', zonecategory_id: 1, route_ids: [26, 30, 69, 170, 347] },
        17: { zone_id: 17, zone_name: 'Торфмаш', zonecategory_id: 1, route_ids: [30] },
        18: { zone_id: 18, zone_name: 'Московский', zonecategory_id: 1, route_ids: [135] },
        20: { zone_id: 20, zone_name: 'Канищево', zonecategory_id: 1, route_ids: [135] },
        23: {
            zone_id: 23,
            zone_name: 'Южный',
            zonecategory_id: 1,
            route_ids: [76, 111, 124, 135, 138, 139, 155, 173, 195, 325, 339, 340, 342],
        },
        24: { zone_id: 24, zone_name: 'Никуличи', zonecategory_id: 1, route_ids: [76, 173, 340] },
        25: { zone_id: 25, zone_name: 'Соколовка', zonecategory_id: 1, route_ids: [60] },
        28: { zone_id: 28, zone_name: 'Строитель', zonecategory_id: 1, route_ids: [111] },
        30: { zone_id: 30, zone_name: 'Филармония', zonecategory_id: 5, route_ids: [60] },
        31: { zone_id: 31, zone_name: 'Драмтеатр', zonecategory_id: 5, route_ids: [60] },
        33: { zone_id: 33, zone_name: 'Стадион "ЦСКА"', zonecategory_id: 5, route_ids: [135] },
        34: { zone_id: 34, zone_name: 'ЦПКиО', zonecategory_id: 5, route_ids: [135] },
    },
    routes: [
        { route_ids: [347], route_type: 'А', route_num: '116М5', route_name: 'А-116 М5МОЛЛ Театральня' },
        { route_ids: [20], route_type: 'А', route_num: '16', route_name: 'А-16' },
        { route_ids: [195], route_type: 'А', route_num: '2', route_name: 'А-2' },
        { route_ids: [111], route_type: 'А', route_num: '3', route_name: 'А-3' },
        { route_ids: [409], route_type: 'А', route_num: '30', route_name: 'А-30' },
        { route_ids: [26], route_type: 'А', route_num: '32', route_name: 'А-32' },
        { route_ids: [76], route_type: 'А', route_num: '6', route_name: 'А-6' },
        { route_ids: [139], route_type: 'М', route_num: '33', route_name: 'М-33' },
        { route_ids: [393], route_type: 'М', route_num: '49', route_name: 'М-49' },
        { route_ids: [135], route_type: 'М', route_num: '50', route_name: 'М-50' },
        { route_ids: [30], route_type: 'М', route_num: '62', route_name: 'М-62' },
        { route_ids: [124], route_type: 'М', route_num: '70', route_name: 'М-70' },
        { route_ids: [138], route_type: 'М', route_num: '71', route_name: 'М-71' },
        { route_ids: [170], route_type: 'М', route_num: '82', route_name: 'М-82' },
        { route_ids: [155], route_type: 'М', route_num: '85', route_name: 'М-85' },
        { route_ids: [173], route_type: 'М', route_num: '96', route_name: 'М-96' },
        { route_ids: [389], route_type: 'М', route_num: '99', route_name: 'М-99' },
        { route_ids: [69], route_type: 'Т', route_num: '12', route_name: 'Т-12' },
        { route_ids: [361], route_type: 'Т', route_num: '5', route_name: 'Т-5' },
    ],
}

export const testForecastData = [
    {
        rid: '361',
        rnum: '5',
        rtype: 'Т',
        fores: [
            {
                obj_id: '00058787',
                arrt: 117,
                last: 'Радиоуниверситет',
                where: 'Ул. Строителей',
                obj_tags: [],
                gos_num: '1082',
                state_id: 3,
                rid: '361',
            },
            {
                obj_id: '00056311',
                arrt: 762,
                last: 'Центр. рынок',
                where: 'Ул. Строителей',
                obj_tags: [],
                gos_num: '1054',
                state_id: 3,
                rid: '361',
            },
        ],
    },
    {
        rid: '139',
        rnum: '33',
        rtype: 'М',
        fores: [
            {
                obj_id: '33126054',
                arrt: 151,
                last: 'Бол. им. Семашко',
                where: 'СПТУ-6',
                obj_tags: [],
                gos_num: 'К833СН',
                state_id: 3,
                rid: '139',
            },
            {
                obj_id: '33117604',
                arrt: 2147483648,
                last: '',
                where: '',
                obj_tags: [],
                gos_num: 'К752СН',
                state_id: 4,
                rid: '139',
            },
        ],
    },
    {
        rid: '409',
        rnum: '30',
        rtype: 'А',
        fores: [
            {
                obj_id: '59849432',
                arrt: 154,
                last: 'Ул. Гагарина',
                where: 'БСПМ',
                obj_tags: ['2'],
                gos_num: 'Х278СХ',
                state_id: 3,
                rid: '409',
            },
            {
                obj_id: '00094962',
                arrt: 892,
                last: 'Автовокзал',
                where: 'БСПМ',
                obj_tags: [],
                gos_num: 'Т933ТМ',
                state_id: 3,
                rid: '409',
            },
            {
                obj_id: '01114378',
                arrt: 1995,
                last: 'ТКПО',
                where: 'БСПМ',
                obj_tags: ['2'],
                gos_num: 'Т949ТМ',
                state_id: 3,
                rid: '409',
            },
        ],
    },
    {
        rid: '30',
        rnum: '62',
        rtype: 'М',
        fores: [
            {
                obj_id: 'EF732844',
                arrt: 238,
                last: 'Бол. им. Семашко',
                where: 'Торфмаш',
                obj_tags: ['2'],
                gos_num: 'А675ТА',
                state_id: 3,
                rid: '30',
            },
            {
                obj_id: '59677312',
                arrt: 956,
                last: 'Торговый городок',
                where: 'Торфмаш',
                obj_tags: ['2'],
                gos_num: 'К346ТЕ',
                state_id: 3,
                rid: '30',
            },
            {
                obj_id: 'EF783946',
                arrt: 2147483648,
                last: '',
                where: '',
                obj_tags: ['2'],
                gos_num: 'В382ТА',
                state_id: 4,
                rid: '30',
            },
        ],
    },
    {
        rid: '138',
        rnum: '71',
        rtype: 'М',
        fores: [
            {
                obj_id: '01100331',
                arrt: 293,
                last: 'Музучилище',
                where: 'Ул. Берёзовая',
                obj_tags: [],
                gos_num: 'Х965РН',
                state_id: 3,
                rid: '138',
            },
            {
                obj_id: '01102140',
                arrt: 645,
                last: 'ТРЦ "Премьер"',
                where: 'Ул. Берёзовая',
                obj_tags: [],
                gos_num: 'Н252РР',
                state_id: 3,
                rid: '138',
            },
            {
                obj_id: '01114997',
                arrt: 899,
                last: 'Университет МВД России',
                where: 'Ул. Берёзовая',
                obj_tags: [],
                gos_num: 'В479РЕ',
                state_id: 3,
                rid: '138',
            },
        ],
    },
    {
        rid: '389',
        rnum: '99',
        rtype: 'М',
        fores: [
            {
                obj_id: '59657637',
                arrt: 411,
                last: 'Гост. «Первомайская»',
                where: 'ОЭМЗ института «Гинцветмет»',
                obj_tags: [],
                gos_num: 'А614СУ',
                state_id: 3,
                rid: '389',
            },
            {
                obj_id: '33122111',
                arrt: 1084,
                last: 'Электросеть',
                where: 'ОЭМЗ института «Гинцветмет»',
                obj_tags: ['2'],
                gos_num: 'Р449ТК',
                state_id: 3,
                rid: '389',
            },
            {
                obj_id: '59534717',
                arrt: 1488,
                last: 'Кинотеатр «Октябрь»',
                where: 'ОЭМЗ института «Гинцветмет»',
                obj_tags: ['2'],
                gos_num: 'К090ТВ',
                state_id: 3,
                rid: '389',
            },
        ],
    },
    {
        rid: '124',
        rnum: '70',
        rtype: 'М',
        fores: [
            {
                obj_id: '59645020',
                arrt: 425,
                last: 'Пл. Победы',
                where: 'Ул. Берёзовая',
                obj_tags: [],
                gos_num: 'А480СУ',
                state_id: 3,
                rid: '124',
            },
        ],
    },
    {
        rid: '155',
        rnum: '85',
        rtype: 'М',
        fores: [
            {
                obj_id: '00164160',
                arrt: 628,
                last: 'Пл. Театральная',
                where: 'П. Южный',
                obj_tags: [],
                gos_num: 'В708РЕ',
                state_id: 3,
                rid: '155',
            },
            {
                obj_id: '59197656',
                arrt: 1310,
                last: 'Зубковой, 23',
                where: 'П. Южный',
                obj_tags: [],
                gos_num: 'У539СА',
                state_id: 3,
                rid: '155',
            },
            {
                obj_id: '00053247',
                arrt: 2147483648,
                last: '',
                where: '',
                obj_tags: [],
                gos_num: 'К800РЕ',
                state_id: 4,
                rid: '155',
            },
        ],
    },
    {
        rid: '135',
        rnum: '50',
        rtype: 'М',
        fores: [
            {
                obj_id: '33070502',
                arrt: 782,
                last: 'Автовокзал',
                where: 'СПТУ-6',
                obj_tags: [],
                gos_num: 'К081ТН',
                state_id: 3,
                rid: '135',
            },
        ],
    },
    {
        rid: '170',
        rnum: '82',
        rtype: 'М',
        fores: [
            {
                obj_id: '33092198',
                arrt: 1142,
                last: 'Ул. Бронная',
                where: 'Октябрьский городок',
                obj_tags: [],
                gos_num: 'Н424РО',
                state_id: 3,
                rid: '170',
            },
            {
                obj_id: '59237207',
                arrt: 1765,
                last: 'Комбайновый завод',
                where: 'Октябрьский городок',
                obj_tags: [],
                gos_num: 'Т087СО',
                state_id: 3,
                rid: '170',
            },
            {
                obj_id: '01101565',
                arrt: 2147483648,
                last: '',
                where: '',
                obj_tags: [],
                gos_num: 'О510РТ',
                state_id: 4,
                rid: '170',
            },
        ],
    },
    {
        rid: '173',
        rnum: '96',
        rtype: 'М',
        fores: [
            {
                obj_id: '01101885',
                arrt: 1716,
                last: 'Новосёлов, 54',
                where: 'П. Никуличи',
                obj_tags: [],
                gos_num: 'С071ВК',
                state_id: 3,
                rid: '173',
            },
            {
                obj_id: '00164723',
                arrt: 1721,
                last: 'Новосёлов, 54',
                where: 'П. Никуличи',
                obj_tags: [],
                gos_num: 'А599СР',
                state_id: 3,
                rid: '173',
            },
            {
                obj_id: '33066757',
                arrt: 1723,
                last: 'Новосёлов, 54',
                where: 'П. Никуличи',
                obj_tags: [],
                gos_num: 'Е267ОХ',
                state_id: 3,
                rid: '173',
            },
        ],
    },
    {
        rid: '393',
        rnum: '49',
        rtype: 'М',
        fores: [
            {
                obj_id: '59869451',
                arrt: 2147483648,
                last: '',
                where: '',
                obj_tags: ['2'],
                gos_num: 'К806ТВ',
                state_id: 4,
                rid: '393',
            },
        ],
    },
    { rid: '347', rnum: '116М5', rtype: 'А', fores: [] },
    { rid: '20', rnum: '16', rtype: 'А', fores: [] },
    { rid: '195', rnum: '2', rtype: 'А', fores: [] },
    { rid: '111', rnum: '3', rtype: 'А', fores: [] },
    { rid: '26', rnum: '32', rtype: 'А', fores: [] },
    { rid: '76', rnum: '6', rtype: 'А', fores: [] },
    { rid: '69', rnum: '12', rtype: 'Т', fores: [] },
]
