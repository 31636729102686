import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { connect } from 'react-redux'

import {
    fetchStationsByLocation,
    getStationsLoaded,
    getStationsLoading,
    getErrors,
    getStations,
} from '../../store/stationSearchByLocationPageSlice'
import StationSearchPageTitle from '../../components/StationSearch/PageTitle'
import ErrorScreen from '../../components/StationSearch/ErrorScreen'
import LoadingScreen from '../../components/StationSearch/LoadingScreen'
import StationsList from '../../components/StationSearch/StationsList'

/* eslint react/prop-types: ["off"] */
const StationSearchByLocationPage = ({ fetchStationsByLocation, stationsLoaded, error, loading, stations }) => {
    const intl = useIntl()

    useEffect(() => {
        fetchStationsByLocation()
    }, [fetchStationsByLocation])

    return (
        <>
            <StationSearchPageTitle
                title={intl.formatMessage({
                    id: 'pages.stationSearchByLocation.title',
                })}
                headTitle={intl.formatMessage({
                    id: 'pages.stationSearchByLocation.htmlHead.title',
                })}
            />
            {loading && (
                <LoadingScreen
                    message={intl.formatMessage({
                        id: 'pages.stationSearchByLocation.LoadingScreen',
                    })}
                />
            )}
            {error && (
                <ErrorScreen
                    message={intl.formatMessage({
                        id: `pages.stationSearchByLocation.errors.${error}`,
                    })}
                />
            )}
            {!loading && !error && (
                <>
                    {stationsLoaded && (
                        <StationsList
                            stations={stations}
                            notFoundMessage={intl.formatMessage({
                                id: 'pages.stationSearchByLocation.NotFound',
                            })}
                            showDistances
                        />
                    )}
                </>
            )}
        </>
    )
}

export default connect(
    state => ({
        stationsLoaded: getStationsLoaded(state),
        error: getErrors(state),
        loading: getStationsLoading(state),
        stations: getStations(state),
    }),
    {
        fetchStationsByLocation,
    },
)(StationSearchByLocationPage)
