import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import CheckBox from '../Checkbox'
import RadioButton from '../RadioButton'

import './DialogLineCheckbox.scss'


const propTypes = {
    type: PropTypes.oneOf(['checkbox', 'radio']),
    title: PropTypes.string.isRequired,
    checked: PropTypes.bool.isRequired,
    handleArg: PropTypes.any,
    handleCheck: PropTypes.func.isRequired,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
}

const defaultProps = {
    type: 'checkbox',
}

function LineSetupDialog({ type, children, title, checked, handleArg, handleCheck }) {
    const handler = useCallback(() => {
        handleCheck(handleArg)
    }, [handleArg, handleCheck])

    return (
        <div onClick={handler} className="dialog-line-checkbox" aria-hidden>
            {
                type === 'radio'
                    ? <RadioButton checked={checked} />
                    : <CheckBox checked={checked} />
            }
            {children}
            <div className="text">{title}</div>
        </div>
    )
}

LineSetupDialog.propTypes = propTypes
LineSetupDialog.defaultProps = defaultProps

export default LineSetupDialog
