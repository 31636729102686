import React from 'react'


export default () => (
    <>
        <path
            d="M25.3032 22.4173L25.3032 22.4173C25.5263 22.1942 25.888 22.1943 26.1112 22.4174C27.3099 23.6162 27.3099 25.567 26.1112 26.7657L26.1111 26.7658C25.9996 26.8774 25.8535 26.9331 25.7072 26.9331C25.5608 26.9331 25.4148 26.8774 25.3032 26.7658L25.3032 26.7658C25.0801 26.5428 25.0801 26.181 25.3032 25.9579L25.3032 25.9579C26.0566 25.2045 26.0566 23.9787 25.3032 23.2253L25.3032 23.2252C25.0801 23.0022 25.0801 22.6405 25.3032 22.4173Z"
            fill="#333333"
            stroke="#333333"
            strokeWidth="0.2"
        />
        <path
            d="M27.271 20.4497C27.271 20.4497 27.271 20.4497 27.271 20.4497C27.494 20.2267 27.8558 20.2266 28.0789 20.4497C30.3627 22.7335 30.3627 26.4498 28.0789 28.7335C27.9674 28.845 27.8213 28.9008 27.675 28.9008C27.5286 28.9008 27.3825 28.845 27.271 28.7335C27.0479 28.5104 27.0479 28.1487 27.271 27.9255C29.1094 26.0872 29.1094 23.0961 27.271 21.2577C27.0479 21.0346 27.0479 20.6729 27.271 20.4497Z"
            fill="#333333"
            stroke="#333333"
            strokeWidth="0.2"
        />
        <path
            d="M29.4668 18.2537C29.4668 18.2537 29.4668 18.2537 29.4668 18.2537C29.6899 18.0306 30.0517 18.0308 30.2747 18.2537C31.9678 19.9469 32.9001 22.1973 32.9 24.5915C32.9 26.9859 31.9678 29.2364 30.2747 30.9295C30.1632 31.041 30.0171 31.0968 29.8708 31.0968C29.7244 31.0968 29.5783 31.041 29.4668 30.9295C29.2437 30.7064 29.2437 30.3447 29.4668 30.1215C30.9438 28.6446 31.7574 26.6804 31.7574 24.5916C31.7574 22.5029 30.9438 20.5387 29.4668 19.0617C29.2437 18.8386 29.2437 18.4769 29.4668 18.2537Z"
            fill="#333333"
            stroke="#333333"
            strokeWidth="0.2"
        />
        <path
            d="M23.4204 17.8665L23.4205 17.8666C23.6233 17.9596 23.7534 18.1625 23.7534 18.3857V30.7977C23.7534 31.0209 23.6234 31.2237 23.4204 31.3169L23.4204 31.3169C23.3441 31.3519 23.2629 31.3691 23.1822 31.3691C23.048 31.3691 22.9154 31.3218 22.8096 31.231L18.5438 27.5652L18.5157 27.5411H18.4787H16.5285C16.213 27.5411 15.9572 27.2853 15.9572 26.9697V22.2137C15.9572 21.8981 16.213 21.6424 16.5285 21.6424H18.4787H18.5157L18.5438 21.6182L22.8096 17.9524L22.8097 17.9524C22.9789 17.8068 23.2175 17.7732 23.4204 17.8665ZM17.1998 22.785H17.0998V22.885V26.2984V26.3984H17.1998H18.0562H18.1562V26.2984V22.885V22.785H18.0562H17.1998ZM22.4456 29.4114L22.6108 29.5534V29.3356V19.8477V19.63L22.4456 19.7719L19.3337 22.4461L19.2988 22.476V22.522V26.6614V26.7073L19.3337 26.7372L22.4456 29.4114Z"
            fill="#333333"
            stroke="#333333"
            strokeWidth="0.2"
        />
    </>
)
