import React from 'react'
import PropTypes from 'prop-types'


const propTypes = {
    routeTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
}

const IconSegments = ({ routeTypes }) => {
    switch (routeTypes.length) {
        case 1:
            return (
                <>
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M23 23V45H38C41.866 45 45 41.866 45 38V23H23Z"
                        className={routeTypes[0]}
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M22 23L45 23L45 10C45 6.13401 41.866 3 38 3L22 3L22 23Z"
                        className={routeTypes[0]}
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M23 23L23 3L10 3C6.13401 3 3 6.134 3 10L3 23L23 23Z"
                        className={routeTypes[0]}
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M23 23L3 23L3 38C3 41.866 6.13401 45 10 45L23 45L23 23Z"
                        className={routeTypes[0]}
                    />
                </>
            )

        case 2:
            return (
                <>
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M25 25V45H38C41.866 45 45 41.866 45 38V25H25Z"
                        className={routeTypes[0]}
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M25 25L45 25L45 10C45 6.13401 41.866 3 38 3L25 3L25 25Z"
                        className={routeTypes[0]}
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M23 23L23 3L10 3C6.13401 3 3 6.134 3 10L3 23L23 23Z"
                        className={routeTypes[1]}
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M23 23L3 23L3 38C3 41.866 6.13401 45 10 45L23 45L23 23Z"
                        className={routeTypes[1]}
                    />
                </>
            )

        case 3:
            return (
                <>
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M25 25V45H38C41.866 45 45 41.866 45 38V25H25Z"
                        className={routeTypes[1]}
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M25 23L45 23L45 10C45 6.13401 41.866 3 38 3L25 3L25 23Z"
                        className={routeTypes[2]}
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M23 23L23 3L10 3C6.13401 3 3 6.134 3 10L3 23L23 23Z"
                        className={routeTypes[0]}
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M23 23L3 23L3 38C3 41.866 6.13401 45 10 45L23 45L23 23Z"
                        className={routeTypes[0]}
                    />
                </>
            )
        case 4:
            return (
                <>
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M25 25V45H38C41.866 45 45 41.866 45 38V25H25Z"
                        className={routeTypes[0]}
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M25 23L45 23L45 10C45 6.13401 41.866 3 38 3L25 3L25 23Z"
                        className={routeTypes[1]}
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M23 23L23 3L10 3C6.13401 3 3 6.134 3 10L3 23L23 23Z"
                        className={routeTypes[2]}
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M23 25L3 25L3 38C3 41.8659 6.13401 45 10 45L23 45L23 25Z"
                        className={routeTypes[3]}
                    />
                </>
            )
        default:
            return null
    }
}

IconSegments.propTypes = propTypes

export default IconSegments
