import React from 'react'
import PropTypes from 'prop-types'


const propTypes = {
    color: PropTypes.string,
}

const Svg = ({ color }) => (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            className="fill"
            style={{ fill: color }}
            d="M17.0711 2.92891C15.1823 1.04016 12.6711 0 10 0C7.32895 0 4.8177 1.04016 2.92895 2.92895C1.0402 4.8177 0 7.32891 0 10C0 12.6711 1.04016 15.1824 2.92891 17.0711C4.81766 18.9598 7.32887 20 9.99996 20C12.6711 20 15.1823 18.9598 17.0711 17.0711C18.9598 15.1823 20 12.6711 20 10C20 7.32891 18.9598 4.8177 17.0711 2.92891ZM16.2424 16.2425C14.575 17.9098 12.358 18.8281 9.99996 18.8281C7.64187 18.8281 5.42492 17.9098 3.75754 16.2425C2.09012 14.575 1.17188 12.3581 1.17188 10C1.17188 7.64195 2.0902 5.425 3.75758 3.75758C5.425 2.09016 7.64191 1.17188 10 1.17188C12.3581 1.17188 14.575 2.09016 16.2425 3.75754C19.6845 7.19965 19.6845 12.8004 16.2424 16.2425Z"
        />
        <path
            className="fill"
            style={{ fill: color }}
            d="M14.4084 13.5798L10.8287 10L14.4084 6.42031C14.6372 6.19148 14.6372 5.82051 14.4084 5.59172C14.1796 5.36289 13.8086 5.36289 13.5797 5.59172L10 9.17141L6.4203 5.59168C6.19151 5.36285 5.82049 5.36285 5.59167 5.59168C5.36284 5.82051 5.36284 6.19148 5.59167 6.42031L9.1714 10L5.59167 13.5798C5.36284 13.8086 5.36284 14.1796 5.59167 14.4084C5.70608 14.5228 5.85604 14.58 6.00596 14.58C6.15592 14.58 6.30589 14.5228 6.4203 14.4084L10 10.8287L13.5798 14.4084C13.6942 14.5228 13.8441 14.58 13.9941 14.58C14.1441 14.58 14.294 14.5228 14.4084 14.4084C14.6372 14.1796 14.6372 13.8086 14.4084 13.5798Z"
        />
    </svg>
)

Svg.propTypes = propTypes

export default Svg
